<template>
  <div id="SiteForgotPassword" class="Main-Content">

    <Page>
      <Layout>
        <LayoutSection>
          <Card :title="$t('Forgot Password')">
            <CardSection>
          
              <template v-if="!emailSent">
                <p>{{ $t('site.forgotPasswordInfo') }}</p>
                <div v-if="error" class="message error">{{ error }}</div>
                <FormLayout>
                  <TextField
                    :label="$t('Email')"
                    v-model="email"
                    ref="email"
                  />
                  <Button @click="forgotPassword" type="primary">{{ $t('Change Password') }}</Button>
                </FormLayout>
              </template>

              <template v-else>
                <p>{{ $t('site.forgotPasswordInfoSent') }}</p>
              </template>

            </CardSection>
          </Card>
        </LayoutSection>
      </Layout>
    </Page>

  </div>
</template>

<script>

export default {
  name: 'SiteForgotPassword',
  metaInfo() {
    return {
      title: `${this.$t('Forgot Password')}`,
    }
  },
  data () {
    return {
      loading: true,
      email: '',
      error: '',
      emailSent: false
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    async forgotPassword() {
      if (!this.email.trim()) {
        this.error = this.$t('validation.enterEmail')
        return
      }
      await this.api.forgotPassword(this.email.trim())
      this.emailSent = true
    }
  },
}

</script>

<style lang="scss" scoped>
.message {
  padding: 5px;
}
.error {
  color: #c00;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
