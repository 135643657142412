<template functional>
  <div id="CampaignNotesBasic">
    <template v-if="typeof props.note.notes === 'string'">
      {{$options.methods.parseSimpleNote(props.note.objectType, props.note.notes, parent.$t)}}
    </template>
    <template v-else-if="props.note.objectType === 'DOMAIN' && props.note.objectAction === 'CREATE'">
      {{parent.$t('CampaignNotes.linkedToCampaign', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectType === 'DOMAIN' && props.note.objectAction === 'DELETE'">
      {{parent.$t('CampaignNotes.unlinkedFromCampaign', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectType === 'CAMPAIGN' && props.note.objectAction === 'CREATE'">
      {{parent.$t('CampaignNotes.addedCampaign', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectAction === 'CREATE'">
      {{`${parent.$t('Create')} ${props.note.objectName}`}}
    </template>
    <template v-else-if="props.note.objectAction === 'DELETE'">
      {{`${parent.$t('Delete')} ${props.note.objectName}`}}
    </template>
    <template v-else>
      <div class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes" :key="index" class="simple-note-content">
          <div 
            v-if="props.note.objectType === 'ABTESTING' && noteInner.attribute === 'AB Test'"
            v-html="parent.$t('CampaignNotes.fromTo', {
              attr: parent.$t('A/B Testing Percentage'),
              old: noteInner.oldValue ? `${noteInner.oldValue}%` : parent.$t('Off'),
              new: noteInner.newValue ? `${noteInner.newValue}%` : parent.$t('Off'),
            })"
          />
          <template v-else-if="noteInner.attribute === 'abExcludedActionIds'">
            <b>{{parent.$t('CampaignNotes.abExcludedActionIdsChanged')}}</b>
          </template>
          <template v-else-if="noteInner.attribute === 'abExcludedActions.add'">
            <b>{{parent.$t('CampaignNotes.abExcludedActionsAdded')}}</b>
            <List v-if="$options.methods.getNewValueJson(noteInner)" tight class="List">
              <ListItem v-for="(action, index) in $options.methods.getNewValueJson(noteInner)" :key="index">
                <PageLink :url="`/#/campaigns/${props.campaignId}/segment-actions/edit/${action.id}`" external>{{action.name}}</PageLink>
              </ListItem>
            </List>
          </template>
          <template v-else-if="noteInner.attribute === 'abExcludedActions.remove'">
            <b>{{parent.$t('CampaignNotes.abExcludedActionsRemoved')}}</b>
            <List v-if="$options.methods.getOldValueJson(noteInner)" tight class="List">
              <ListItem v-for="(action, index) in $options.methods.getOldValueJson(noteInner)" :key="index">
                <PageLink :url="`/#/campaigns/${props.campaignId}/segment-actions/edit/${action.id}`" external>{{action.name}}</PageLink>
              </ListItem>
            </List>
          </template>
          <template v-else-if="noteInner.attribute === 'abExcludedExperimentIds'">
            <b>{{parent.$t('CampaignNotes.abExcludedExperimentIdsChanged')}}</b>
          </template>
          <template v-else-if="noteInner.attribute === 'abExcludedExperiments.add'">
            <b>{{parent.$t('CampaignNotes.abExcludedExperimentsAdded')}}</b>
            <List v-if="$options.methods.getNewValueJson(noteInner)" tight class="List">
              <ListItem v-for="(experiment, index) in $options.methods.getNewValueJson(noteInner)" :key="index">
                <PageLink :url="`/#/campaigns/${props.campaignId}/experiments/edit/${experiment.id}`" external>{{experiment.name}}</PageLink>
              </ListItem>
            </List>
          </template>
          <template v-else-if="noteInner.attribute === 'abExcludedExperiments.remove'">
            <b>{{parent.$t('CampaignNotes.abExcludedExperimentsRemoved')}}</b>
            <List v-if="$options.methods.getOldValueJson(noteInner)" tight class="List">
              <ListItem v-for="(experiment, index) in $options.methods.getOldValueJson(noteInner)" :key="index">
                <PageLink :url="`/#/campaigns/${props.campaignId}/experiments/edit/${experiment.id}`" external>{{experiment.name}}</PageLink>
              </ListItem>
            </List>
          </template>
          <template v-else>
            <template 
              v-if="$options.methods.getDoAsJson($options.methods.getOldValueJson(noteInner), $options.methods.getNewValueJson(noteInner)) 
                && typeof $options.methods.getOldValueJson(noteInner) === 'object' 
                && typeof $options.methods.getNewValueJson(noteInner) === 'object'"
            >
              <b>{{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}</b>
              <table style="margin-top: 2px;" class="table table-bordered change-table">
                <tr>
                  <td></td>
                  <td><b>{{parent.$t('Old Value')}}</b></td>
                  <td><b>{{parent.$t('New Value')}}</b></td>
                </tr>
                <template v-for="(key, i) of Object.keys($options.methods.getOldValueJson(noteInner))">
                  <tr v-if="!$options.methods.isEqualOldNew($options.methods.getOldValueJson(noteInner)[key], $options.methods.getNewValueJson(noteInner)[key])" :key="i">
                    <td>{{$options.methods.niceKey(key)}}</td>
                    <td>{{$options.methods.getOldValueJson(noteInner)[key]}}</td>
                    <td>{{$options.methods.getNewValueJson(noteInner)[key]}}</td>
                  </tr>
                </template>
              </table>
            </template>
            <template v-else-if="$options.methods.getDoAsJson($options.methods.getOldValueJson(noteInner), $options.methods.getNewValueJson(noteInner))">
              <b>{{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}</b>
              <table style="margin-top: 2px;" class="table table-bordered change-table">
                <template v-for="(key, i) of Object.keys($options.methods.getNewValueJson(noteInner))">
                  <tr v-if="$options.methods.getNewValueJson(noteInner)[key] !== null && $options.methods.getNewValueJson(noteInner)[key] !== undefined" :key="i">
                    <td>{{$options.methods.niceKey(key)}}</td>
                    <td>{{$options.methods.getNewValueJson(noteInner)[key]}}</td>
                  </tr>
                </template>
              </table>
            </template>
            <template v-else-if="noteInner.newValue !== undefined">
              <div v-html="parent.$t('CampaignNotes.fromTo', {
                attr: $options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t),
                old: $options.methods.fixValue(noteInner.oldValue, parent.$t, noteInner.attribute, props.languages),
                new: $options.methods.fixValue(noteInner.newValue, parent.$t, noteInner.attribute, props.languages),
              })"/>
            </template>
            <template v-else-if="noteInner.newValue === undefined && noteInner.oldValue === undefined">
              {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {capitalize} from 'lodash'
export default {
  name: 'CampaignNotesBasic',
  props: {
    note: {
      type: Object,
      required: true
    },
    campaignId: {
      type: Number,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  methods: {
    parseSimpleNote(objectType, note, translationFunction){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      if (objectType === 'INCENTIVE') {
        returnNoteStr = returnNoteStr.replace(/^Added incentive/, translationFunction('CampaignNotes.Added incentive'))
        returnNoteStr = returnNoteStr.replace(/^Deleted incentive/, translationFunction('CampaignNotes.Deleted incentive'))
      } else if (objectType === 'CAMPAIGN') {
        returnNoteStr = returnNoteStr.replace(/^Added campaign/, translationFunction('CampaignNotes.Added campaign'))
      } else if (objectType === 'DOMAIN') {
        returnNoteStr = returnNoteStr.replace(/^Linked/, translationFunction('CampaignNotes.Linked'))
        returnNoteStr = returnNoteStr.replace(/to campaign$/, translationFunction('CampaignNotes.to campaign'))
        returnNoteStr = returnNoteStr.replace(/^Unlinked/, translationFunction('CampaignNotes.Unlinked'))
        returnNoteStr = returnNoteStr.replace(/from campaign$/, translationFunction('CampaignNotes.from campaign'))
      }
      return returnNoteStr
    },
    getIsBothJson(noteInner) {
      let isBothJson = true
      try {
        JSON.parse(noteInner.oldValue)
      } catch(e) {
        isBothJson = false
      }
      try {
        JSON.parse(noteInner.newValue)
      } catch(e) {
        isBothJson = false
      }
      return isBothJson
    },
    getOldValueJson(noteInner) {
      let oldValueJson
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch (e) {
        return ''
      }
      return oldValueJson
    },
    getNewValueJson(noteInner) {
      let newValueJson
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch (e) {
        return ''
      }
      return newValueJson
    },
    parseAttributeForTrans(attribute, translationFunction) {
      return translationFunction(`${attribute}`)
    },
    isEqualOldNew(oldValue, newValue) {
      if (oldValue === null || oldValue === undefined) oldValue = ''
      if (newValue === null || newValue === undefined) newValue = ''
      return JSON.stringify(oldValue) === JSON.stringify(newValue)
    },
    getOfferUrl(id, campaignId) {
      return `/#/campaigns/${campaignId}/offers/edit/${id}`
    },
    getDoAsJson(oldValueJson, newValueJson) {
      let doAsJson = false
      if (oldValueJson && newValueJson && (typeof oldValueJson === 'object')) {
        doAsJson = true
      }
      return doAsJson
    },
    niceKey(val) {
      return val.charAt(0).toUpperCase() + val.substr(1).replace(/([A-Z])/g, ' $1')
    },
    fixValue(value, translationFunction, attribute, languages) {
      if (value === null || value === undefined || value === '') {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'boolean') {
        return translationFunction(value ? 'filters.TRUE.label' : 'filters.FALSE.label')
      }
      if (typeof(value) === 'string' && attribute === 'Status') {
        return translationFunction(capitalize(value))
      }
      if (typeof(value) === 'string' && ['Language'].includes(attribute)) {
        const langObj = (languages || []).find(l => l.value === value)
        return langObj && langObj.label || '-'
      }
      if (typeof(value) === 'object') {
        return JSON.stringify(value)
      }
      if (typeof(value) === 'string' && value.match(/19[0-9][0-9]-[0-9][0-9]-[0-9][0-9]/) && parseInt(value.substr(0, 4), 10) < 1980) {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'string' && value.indexOf('cloudfront.net') > -1) {
        return `<div style="background: url(${value});
          width: 30px;
          height: 30px;
          background-size: contain;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center bottom;
        "></div>`
      }
      return value
    },
  }
}
</script>

<style lang="scss">
#CampaignNotesBasic {
  .note-group-section {
    .simple-note-content + .simple-note-content {
      margin-top: 5px;
    }
    .List {
      margin-top: 3px;
    }
  }
}
</style>