<template>
  <div class="Main-Inner">
    <div class="Main-Content">
      <LoadingLayout />
    </div>
  </div>
</template>

<script>

export default {
  name: 'RedirectLoginLayout',
  async mounted() {

    const accountId = this.$route.query.accountId
    const loginAs = this.$route.query.loginAs
    const become = this.$route.query.become
    const hash = this.$route.query.hash
    const ts = this.$route.query.ts

    await this.api.externalBecomeAccount(accountId, loginAs, become, hash, ts)
    
    window.location.href = '/'
  }
}
</script>