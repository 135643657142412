<template>
  <div class="Main-Inner">
    <div class="Main-Content padding">
      <LoadingLayout />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceLink',
  data() {
    return {
      error: false,
    }
  },
  mounted() {

    const type = this.$route.params.type
    const value = this.$route.params.value

    const simpleTypes = ['campaign', 'session', 'notification', 'stream', 'conversionDefinition']
    const complexTypes = ['offer', 'segment', 'segmentAction', 'experiment', 'order']

    // simply create link
    if (simpleTypes.includes(type)) {
      switch (type) {
        case 'campaign':
          this.$router.replace({ name: 'CampaignEditDetails', params: { id: value } })
          break
        case 'session':
          this.$router.replace({ name: 'SessionViewerLayout', query: { sk: value } })
          break
        case 'notification':
          this.$router.replace({ name: 'NotificationsEditBase', params: { id: value } })
          break
        case 'stream':
          this.$router.replace({ name: 'StreamsEdit', params: { streamId: value } })
          break
        case 'conversionDefinition':
          this.$router.replace({ name: 'AccountConversionDefinitions', query: { showId: value } })
          break
      }
    } else if (complexTypes.includes(type)) {
      this.api.getResourceLinkData(type, value)
        .then(data => {
          const campaignId = data.campaignId || -1
          const orderDate = data.orderDate || ''
          switch (type) {
            case 'offer':
              this.$router.replace({ name: 'CampaignEditOffersEdit', params: { id: campaignId, offerId: value } })
              break
            case 'segment':
              this.$router.replace({ name: 'CampaignEditSegmentationEdit', params: { id: campaignId, segmentId: value } })
              break
            case 'segmentAction':
              this.$router.replace({ name: 'CampaignEditSegmentActionsEdit', params: { id: campaignId, actionId: value } })
              break
            case 'experiment':
              this.$router.replace({ name: 'CampaignEditExperimentsEdit', params: { id: campaignId, experimentId: value } })
              break
            case 'order':
              this.$router.replace({ name: 'AccountConversions', query: { startDate: orderDate, type: 'order', orderNumber: value } })
              break
          }
        })
        .catch(() => {
          this.error = true
        })
    } else {
      this.error = true
    }
  },
}
</script>

<style scoped>
  .padding {
    padding-top: 32px;
  }
</style>