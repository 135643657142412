const CDP_ACCESS = {
  INSIGHTS: 'b',
  AUDIENCES: 'c',
  AUTOMATIONS: 'd',
  PROFILES: 'e',
  MERCHANDISING: 'f',
  SMS: 'g',
  WEB_PUSH: 'h',
  INTEGRATIONS: 'i',
  EXPORTS: 'j',
  MAPPING: 'k',
  DATA_DICTIONARY: 'l',
  ERRORS: 'm',
  PII: 'n',
  SHOW_PII: 'o',
  PIPELINES: 'p',
}

export function jwtToPortalUser(d) {

  const cdpAccessJwt = typeof d.acp === 'string' ? d.acp.split('|') : []
  const cdpAccess = {
    insights: cdpAccessJwt.includes(CDP_ACCESS.INSIGHTS),
    audiences: cdpAccessJwt.includes(CDP_ACCESS.AUDIENCES),
    automations: cdpAccessJwt.includes(CDP_ACCESS.AUTOMATIONS),
    pipelines: cdpAccessJwt.includes(CDP_ACCESS.PIPELINES),
    profiles: cdpAccessJwt.includes(CDP_ACCESS.PROFILES),
    merchandising: cdpAccessJwt.includes(CDP_ACCESS.MERCHANDISING),
    sms: cdpAccessJwt.includes(CDP_ACCESS.SMS),
    webpush: cdpAccessJwt.includes(CDP_ACCESS.WEB_PUSH),
    integrations: cdpAccessJwt.includes(CDP_ACCESS.INTEGRATIONS),
    exports: cdpAccessJwt.includes(CDP_ACCESS.EXPORTS),
    mapping: cdpAccessJwt.includes(CDP_ACCESS.MAPPING),
    dataDictionary: cdpAccessJwt.includes(CDP_ACCESS.DATA_DICTIONARY),
    errors: cdpAccessJwt.includes(CDP_ACCESS.ERRORS),
    pii: cdpAccessJwt.includes(CDP_ACCESS.PII),
    showPii: cdpAccessJwt.includes(CDP_ACCESS.SHOW_PII)
  }

  return {
    userId: d.uid,
    accountId: d.aid,
    onBehalfOfAccountId: d.obo,
    securityLoginVersion: d.slv,
    accountTimezone: d.atz,
    isSuper: d.isp === 1,
    isAgency: d.iag === 1,
    isAdmin: d.iad === 1,
    isDemo: d.ide === 1,
    isMultiAccount: d.ima === 1,
    // access
    hasCampaignAccess: d.acc === 1,
    hasInvoiceAccess: d.aci === 1,
    hasCreativeAccess: d.acw === 1,
    hasTransactionAccess: d.act === 1,
    hasConversionDefinitionAccess: d.acd === 1,
    hasNotificationAccess: d.ant === 1,
    hasSmsAccess: d.asm === 1,
    hasRecommendationsAccess: d.are === 1,
    hasPrivacyIdAccess: d.api === 1,
    //
    hasCdpAccess: cdpAccess,
    cdpApiHostname: d.cda,
    // enabled
    isSmartEnabled: d.ist === 1,
    isCdpEnabled: d.icd === 1,
    webPushEnabled: d.ewp === 1,
    smsEnabled: d.esm === 1,
    recommendationsEnabled: d.ere === 1,
    privacyIdEnabled: d.epi === 1,
    //
    twoFactorAuthentication: d.fa2,
    sessionKey: ''
  }
}