<template>
  <div id="SiteLogin" class="Main-Content">

    <Page>
      <Layout>
        <LayoutSection>
          <Card :title="$t('Login')" v-if="!twoFactor && !enterMobile">
            <form autocomplete v-on:keyup.enter="login">
              <CardSection>
                <div v-if="error" class="message error">{{ error }}</div>
                <div v-if="message" class="message">{{ message }}</div>

                <FormLayout>

                  <TextField
                    :label="$t('Email')"
                    v-model="email"
                    :disabled="trying"
                    ref="email"
                    name="email"
                  />
                  <TextField
                    :label="$t('Password')"
                    type="password"
                    v-model="password"
                    :disabled="trying"
                    ref="password"
                    name="password"
                  />

                  <Button type="primary" id="login" fullWidth @click="login" :disabled="trying">{{ $t('Login') }}</Button>
                </FormLayout>

              </CardSection>
              <CardSection>
                <Stack distribution="fillEvenly">
                  <Button fullWidth @click="$router.push('/site/forgotPassword/')">{{ $t('I forgot my password') }}</Button>
                  <Button fullWidth @click="$router.push('/site/register/')">{{ $t('Register') }}</Button>
                </Stack>
              </CardSection>
            </form>
          </Card>
          <Card v-else-if="twoFactor">
            <CardSection>
              <div class="message">{{$t('SiteLogin.A code is being sent to you given your chosen Two Factor method')}}</div>
              <FormLayout>
                <TextField
                  :label="$t('Code')"
                  v-model.trim="code"
                  :disabled="trying"
                  ref="code"
                  name="code"
                />
                <Button type="primary" fullWidth @click="validateTwoFactor" :disabled="trying">{{$t('Validate Code')}}</Button>
              </FormLayout>
            </CardSection>
          </Card>
          <Card v-else-if="enterMobile">
            <CardSection>
              <div class="message">{{$t('SiteLogin.Mobile Two-factor authentication required')}}</div>
              <FormLayout>
                <FormLayoutGroup>
                  <PhoneInput
                    :value="{
                      prefix: mobileCountryCode,
                      localNumber: mobileNumber
                    }"
                    :prefixData="prefixPhoneValues"
                    @update:value="updateMobile"
                    :disabled="trying"
                  />
                </FormLayoutGroup>
                <Button
                  type="primary"
                  fullWidth
                  @click="setMobileNumberForUser"
                  :disabled="trying || mobileInvalid">{{$t('Set phone number')}}</Button>
              </FormLayout>
            </CardSection>
          </Card>
        </LayoutSection>
      </Layout>
    </Page>
    
  </div>
</template>

<script>
import PhoneInput from '../components/ui/PhoneInput.vue'
export default {
  name: 'SiteLogin',
  components: {
    PhoneInput
  },
  metaInfo() {
    return {
      title: `${this.$t('Login')}`,
    }
  },
  data () {
    return {
      error: '',
      message: '',
      trying: false,
      email: '',
      password: '',
      twoFactor: false,
      code: '',
      redirectTo: '',
      enterMobile: false,
      mobileNumber: null,
      mobileCountryCode: null,
      mobileInvalid: false,
      prefixPhoneValues: []
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  computed: {
  },
  methods: {
    updateMobile(obj) {
      this.mobileCountryCode = obj.prefix
      this.mobileNumber = obj.localNumber
      this.mobileInvalid = !obj.isValid || !obj.prefix || !obj.localNumber
    },
    login() {
      this.error = ''
      this.message = ''
      if (!this.email) {
        this.error = this.$t('SiteLogin.Please enter an email')
        this.$refs.email.focus()
        return
      }
      if (!this.password) {
        this.error = this.$t('SiteLogin.Please enter a password')
        this.$refs.password.focus()
        return
      }
      this.trying = true
      this.$ui.progress.start()
      // also sends language through so we can set defaultLanguage for user to current viewed lang
      return this.api.login(this.email.trim(), this.password.trim(), this.currentLocale || 'en')
        .then(async (response) => {
          await this.processPostLogin(response)

          // 2 factor mobile number required
          if (this.theUser.twoFactorAuthentication === 'mr') {
            this.enterMobile = true
            this.prefixPhoneValues = await this.api.getPhonePrefixesList()
          // 2 factor and not passed
          } else if (this.theUser.twoFactorAuthentication && this.theUser.twoFactorAuthentication.substr(0, 1) !== 'p') {
            return this.sendCode()
          } else {
            await this.loadExtraDetails()

            if (this.$route.query && this.$route.query.next) {
              this.$router.push(this.$route.query.next)
              .catch(() => { console.log('Possibly caught by navigation gaurd: super mobile (other)') })
            } else {
              this.routeBasedOnUser()
            }
          }
        })
        .catch((err) => {
          this.error = this.$t(err.message, err.data)
        })
        .finally(() => {
          this.$ui.progress.done()
          this.trying = false
        })
    },
    setMobileNumberForUser() {
      this.error = ''
      this.message = ''

      if (!this.mobileCountryCode || !this.mobileNumber) {
        this.$ui.alert({
          title: 'Error',
          content: 'Required fields missing'
        })
        return
      }

      this.trying = true
      this.api.setMobileFor2faPreLogin(this.mobileCountryCode, this.mobileNumber)
      .then(this.sendCode)
      .catch((err) => {
        this.error = err.message
      })
      .finally(() => {
        this.trying = false
      })
    },
    sendCode() {
      return this.api.twoFactorSendCode()
        .then(() => {
          this.trying = false
          this.twoFactor = true
        })
        .catch((err) => {
          this.error = err.message
        })
        .finally(() => {
          this.trying = false
        })
    },
    validateTwoFactor() {
      this.error = ''
      this.message = ''
      if (!this.code) {
        this.error = this.$t('Code Required')
        return
      }
      this.trying = true
      this.$ui.progress.start()
      return this.api.twoFactorValidateCode(this.code)
        .then(async (response) => {
          // all is good!
          await this.processPostLogin(response)
          await this.loadExtraDetails()

          if (this.$route.query && this.$route.query.next) {
            this.$router.push(this.$route.query.next)
          } else {
            this.routeBasedOnUser()
          }
        })
        .catch((err) => {
          this.error = err && err.message
        })
        .finally(() => {
          this.$ui.progress.done()
          this.trying = false
        })
    },
    routeBasedOnUser() {
      if (this.theUser.isSuper) {
        this.$router.push('/campaigns/')
          .catch(() => {
            console.log('Possibly by navigation guard: super mobile (campaigns)')
          })
      } else if (this.theUser.isSmartEnabled) {
        this.$router.push('/campaigns/')
      } else if (this.theUser.isCdpEnabled) {
        this.$router.push('/cdp')
      } else if (this.theUser.privacyIdEnabled) {
        this.$router.push('/privacy-id')
      } else {
        this.$router.push('/')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.message {
  padding: 5px;
  padding-bottom: 25px;
}
.error {
  color: #c00;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
