<template>
  <Sheet
    :visible="visible"
    :title="$t('Campaign Notes')"
    :width="700"
    @close="visible = false"
    padded
  >
    <div id="NotesModal">
      <SkeletonCard v-if="initialloading"/>
      <template v-else>
        <Card>
          <CardSection>
            <div class="manual-note-input-wrapper">
              <TextField class="manual-note-input-textfield" v-model="newNote" :disabled="saving" multiline/>
              <Button 
                class="manual-note-input-button"
                type="primary" 
                @click="saveNewNote" 
                :disabled="disableSave"
              >{{$t('Add Note')}}</Button>
            </div>
          </CardSection>
        </Card>
        <Card>
          <CardSection style="padding-bottom: 8px;">
            <TextField
              v-model="filter"
              :placeholder="$t('Filter')"
              @keydown.enter="reloadNotes"
            >
              <template #right>
                <Button
                  @click="reloadNotes"
                  type="primary"
                >{{$t('Filter')}}</Button>
              </template>
            </TextField>
          </CardSection>
          <Tabs
            :tabs="tabs"
            v-model="selectedTab"
          />
          <div class="Modal-notes">
            <div v-if="loading" class="Modal-notes-none">{{$t('Loading')}} ...</div>
            <div v-if="!loading && notes.length === 0" class="Modal-notes-none">
              <span v-if="filter && showLoadMore">{{$t('notes.loadMoreFilteringMessage')}}</span>
              <span v-else-if="!loadingMore">{{$t('Nothing to see here')}} ...</span>
            </div>
            <template v-if="notes.length">
              <transition-group :name="'slide'">
                <div
                  v-for="note in notes"
                  class="note"
                  :key="note.id"
                >
                  <div 
                    class="head"
                  >
                    <span class="type">
                      <PageLink 
                        v-if="getObjectLink(note) && note.objectType === 'INCENTIVE'" 
                        :url="getObjectLink(note)" 
                        external
                      >#{{ note.objectId }} — {{ getNoteTypeName(note) }}</PageLink>
                      <PageLink 
                        v-else-if="getObjectLink(note)" 
                        :url="getObjectLink(note)"
                        external
                      >{{ getNoteTypeName(note) }}</PageLink>
                      <span v-else>{{ getNoteTypeName(note) }}</span>
                      <div><Caption>{{ getNoteType(note) }}</Caption></div>
                    </span>

                    <div class="note-info">
                      <Formatter class="note-date" type="dateTimeShort" :value="note.createdOn" />
                      <div class="note-author">{{ note.createdByName }}</div>
                    </div>
                    <span v-if="showDelete(note)" class="delete">
                      <Icon @click="deleteNote(note)" icon="fas fa-times"/>
                    </span>
                  </div>
                  <div class="details">
                    <CampaignNotesManualNote v-if="note.objectType === 'NOTES'" :note="note"/>
                    <CampaignNotesSegment v-else-if="note.objectType === 'SEGMENT'" :note="note" :campaignId="campaignId"/>
                    <CampaignNotesSegmentAction v-else-if="note.objectType === 'SEGMENT_ACTION'" :note="note" :campaignId="campaignId"/>
                    <CampaignNotesExperiment v-else-if="note.objectType === 'EXPERIMENT'" :note="note" :campaignId="campaignId"/>
                    <CampaignNotesOffer v-else-if="note.objectType === 'INCENTIVE'" :note="note" :campaignId="campaignId" :languages="languages"/>
                    <CampaignNotesBasic v-else :note="note" :languages="languages" :campaignId="campaignId"/>
                  </div>
                  <!-- <pre style="width: 100%;
                    max-width: 590px;
                    overflow-x: scroll;">{{note}}</pre> -->
                </div>
              </transition-group>
            </template>
          </div>
          <div v-if="showLoadMore" class="footer">
            <Button v-if="!loadingMore" :fullWidth="true" @click="loadMoreNotes">{{$t('CampaignNotes.Load More')}}</Button>
            <SkeletonBodyText v-else/>
          </div>
        </Card>
      </template>
    </div>

  </Sheet>
</template>

<script>
// HACK: This whole file is the biggest hack you can imagine. It is shit. It works. But it is shit.
import ScrollShadow from '../ui/ScrollShadow'
import CampaignNotesManualNote from './CampaignNotesManualNote'
import CampaignNotesSegment from './CampaignNotesSegment'
import CampaignNotesSegmentAction from './CampaignNotesSegmentAction'
import CampaignNotesExperiment from './CampaignNotesExperiment'
import CampaignNotesOffer from './CampaignNotesOffer'
import CampaignNotesBasic from './CampaignNotesBasic'
import {dayjs,mysqlFormat} from '../../classes/utils/DateUtil'
const ELEMENTS_SHOWN = 50

function parseNotes(notes) {
  const rv = []
  if (!notes) return rv
  for (const note of notes) {
    let n = note
    try {
      const obj = JSON.parse(note.notes)
      n.notes = obj
    } catch (e) {
      rv.push(note)
      continue
    }

    const includeAddition = ( 
      note.objectType === 'SEGMENT' 
      || note.objectType === 'INCENTIVE' 
      || note.objectType === 'SEGMENT_ACTION'
      || note.objectType === 'EXPERIMENT'
    ) && (note.objectAction === 'UPDATE' || !note.objectAction)
    if (includeAddition) {
      const noteObjectAddition = {}
      n.notes.forEach((noteInner) => {
        if (noteInner.attribute === 'RULESET' && ['DELETE'].includes(noteInner.action)) {
          if (!noteObjectAddition['RULESET']) {
            noteObjectAddition['RULESET'] = []
          }
          noteObjectAddition['RULESET'].push(noteInner)
        } else if (noteInner.attribute === 'RULE') {
          if (!noteObjectAddition['RULE']) {
            noteObjectAddition['RULE'] = []
          }
          noteObjectAddition['RULE'].push(noteInner)
        } else if (noteInner.attribute === 'SEGMENT_ACTION') {
          if (!noteObjectAddition['SEGMENT_ACTION']) {
            noteObjectAddition['SEGMENT_ACTION'] = []
          }
          noteObjectAddition['SEGMENT_ACTION'].push(noteInner)
        } else if (noteInner.attribute === 'OFFER') {
          if (!noteObjectAddition['OFFER']) {
            noteObjectAddition['OFFER'] = []
          }
          noteObjectAddition['OFFER'].push(noteInner)
        } else if (noteInner.attribute === 'EXPERIMENT') {
          if (!noteObjectAddition['EXPERIMENT']) {
            noteObjectAddition['EXPERIMENT'] = []
          }
          noteObjectAddition['EXPERIMENT'].push(noteInner)
        } else if (noteInner.attribute === 'TEXTJSON') {
          if (!noteObjectAddition['TEXTJSON']) {
            noteObjectAddition['TEXTJSON'] = []
          }
          noteObjectAddition['TEXTJSON'].push(noteInner)
        } else {
          if (!noteObjectAddition['OTHER']) {
            noteObjectAddition['OTHER'] = []
          }
          noteObjectAddition['OTHER'].push(noteInner)
        }
      })
      n.notes = {
        ...n.notes,
        ...noteObjectAddition
      }
    }
    rv.push(n)
  }
  // console.log(rv)
  return rv
}

export default {
  name: 'CampaignNotesSheet',
  data() {
    return {
      visible: false,
      notes: [],
      languages: [],
      loading: false,
      initialloading: true,
      loadingMore: false,
      selectedTab: 'ALL',
      filter: '',
      newNote: '',
      campaignId: null,
      // options: {},
      saving: false,
      // initialsColor: {},
      sessionKey: null,
      accountRulesets: null,
      limit: ELEMENTS_SHOWN
    }
  },
  created() {
    this.eventHub.$on('showCampaignNotes', (campaignId, type = 'ALL', filter = '') => {
      this.notes = []
      this.languages = []
      this.accountRulesets = []
      this.sessionKey = null
      this.visible = true
      this.limit = ELEMENTS_SHOWN
      this.filter = filter ? `#${filter.toString()}` : ''
      this.selectedTab = type
      this.campaignId = campaignId
      this.$watch('selectedTab', this.reloadNotes)
      this.initialloading = true
      this.$ui.progress.start()
      Promise.all([
        this.api.getCampaignNotes(this.campaignId, this.limit, this.selectedTab, this.filter),
        this.api.getAccountRulesetsForSegmentation(),
        this.api.getSessionKey(),
        this.api.getLanguageList()
      ])
        .then((data) => { 
          const [notes, accountRulesets, sessionKey, languages] = data
          this.notes = parseNotes(notes.data || notes)
          this.accountRulesets = accountRulesets
          this.sessionKey = sessionKey
          this.languages = languages

          window.RulesetEditorLoader
            .load()
            .then(lib => {
              this.$options.components.RuleSummary = lib.createRuleSummaryComponent({
                locale: this.theUser.defaultLanguage,
                authKey: this.sessionKey,
                campaignId: this.campaignId,
                accountRulesets: this.accountRulesets
              })
              this.initialloading = false 
            })
        })
        .catch(this.apiCatch)
        .finally(() => {
          this.$ui.progress.done()
        })
    })
  },
  beforeDestroy() {
    this.eventHub.$off('showCampaignNotes')
  },
  computed: {
    showLoadMore() {
      return !this.loading && this.notes.length && this.notes.length >= this.limit
    },
    tabs() {
      return [
        { id: 'ALL', label: this.$t('All') },
        { id: 'NOTES', label: this.$t('Notes') },
        { id: 'CAMPAIGN', label: this.$t('Campaign') },
        { id: 'INCENTIVE', label: this.$t('Offer') },
        { id: 'SEGMENT_ACTION', label: this.$t('campaignEdit.segmentAction.targetingAction') },
        { id: 'EXPERIMENT', label: this.$t('campaignEdit.experiment') },
        { id: 'SEGMENT', label: this.$t('Segment') },
        { id: 'DOMAIN', label: this.$t('Domain') },
        { id: 'ABTESTING', label: this.$t('A/B Testing') }
      ]
    },
    disableSave() {
      return this.saving || 
        !this.newNote || 
        (this.newNote && this.newNote.charAt(0) === '{') || 
        (this.newNote && this.newNote.charAt(0) === '[')
    }
  },
  methods: {
    reloadNotes() {
      this.loading = true
      this.notes = []
      this.api.getCampaignNotes(this.campaignId, this.limit, this.selectedTab, this.filter)
        .then((response) => {
          this.notes = parseNotes(response.data || response)
          this.loading = false
        })
        .catch(this.apiCatch)
    },
    loadMoreNotes() {
      this.limit += ELEMENTS_SHOWN
      this.loadingMore = true
      this.api.getCampaignNotes(this.campaignId, this.limit, this.selectedTab, this.filter)
        .then((response) => { 
          this.notes = parseNotes(response.data || response)
          this.loadingMore = false
        })
        .catch(this.apiCatch)
    },
    getNoteTypeIcon(note) {
      if (note.objectType === 'NOTES') {
        return 'far fa-edit'
      }
      if (note.objectType === 'INCENTIVE') {
        return 'fas fa-tag'
      }
      if (note.objectType === 'CAMPAIGN') {
        return 'fas fa-star'
      }
      if (note.objectType === 'SEGMENT') {
        return 'fas fa-list-ul'
      }
      if (note.objectType === 'SEGMENT_ACTION') {
        return 'fas fa-layer-group'
      }
      if (note.objectType === 'EXPERIMENT') {
        return 'fas fa-flask'
      }
      if (note.objectType === 'DOMAIN') {
        return 'fas fa-link'
      }
      if (note.objectType === 'ABTESTING') {
        return 'fas fa-percentage'
      }
      return 'fas fa-question'
    },
    getObjectLink(note) {
      if (note.objectType === 'INCENTIVE') {
        return `/#/campaigns/${this.campaignId}/offers/edit/${note.objectId}`
      }
      if (note.objectType === 'CAMPAIGN') {
        return `/#/campaigns/${this.campaignId}/details/`
      }
      if (note.objectType === 'SEGMENT') {
        return `/#/campaigns/${this.campaignId}/segmentation/segment/${note.objectId}/`
      }
      if (note.objectType === 'SEGMENT_ACTION') {
        return `/#/campaigns/${this.campaignId}/segment-actions/edit/${note.objectId}/`
      }
      if (note.objectType === 'EXPERIMENT') {
        return `/#/campaigns/${this.campaignId}/experiments/edit/${note.objectId}/`
      }
      if (note.objectType === 'DOMAIN') {
        return `/#/campaigns/${this.campaignId}/domains/`
      }
      if (note.objectType === 'ABTESTING') {
        return `/#/campaigns/${this.campaignId}/options`
      }
      return ''
    },
    getNoteTypeName(note) {
      if (note.objectType === 'NOTES') {
        return this.$t('Manual')
      }
      if (note.objectType === 'INCENTIVE') {
        return note.objectName || this.$t('Incentive')
      }
      if (note.objectType === 'CAMPAIGN') {
        return note.objectName || this.$t('Campaign')
      }
      if (note.objectType === 'SEGMENT') {
        return note.objectName || this.$t('Segment')
      }
      if (note.objectType === 'SEGMENT_ACTION') {
        return note.objectName || this.$t('campaignEdit.segmentAction.targetingAction')
      }
      if (note.objectType === 'EXPERIMENT') {
        return note.objectName || this.$t('campaignEdit.experiment')
      }
      if (note.objectType === 'DOMAIN') {
        return this.$t('Domain')
      }
      if (note.objectType === 'ABTESTING') {
        return note.objectName || this.$t('A/B Testing')
      }
      return this.$t('Unknown')
    },
    getNoteType(note) {
      const t = this.tabs.find(t => t.id === note.objectType)
      return t ? t.label : '?'
    },
    showDelete(note) {
      if (this.theUser && note.objectType === 'NOTES') {
        const currDate = dayjs.tz(dayjs().utc(), 'UTC').tz(this.theUser.accountTimezone)
        if (currDate.diff(dayjs.tz(note.createdOn, this.theUser.accountTimezone), 'minutes') < 10) {
          return true
        }
      }
      return false
    },
    saveNewNote() {
      if (this.newNote.trim().length === 0) {
        return
      }
      this.$ui.progress.start()
      this.saving = true
      this.api.addCampaignNote(this.campaignId, this.newNote)
        .then((newNoteId) => {
          let formattedNewNote = this.newNote
          // add to list
          this.notes.unshift({
            id: newNoteId,
            campaignId: this.campaignId,
            notes: formattedNewNote,
            objectType: 'NOTES',
            objectId: this.campaignId,
            userId: -1,
            accountId: -1,
            onBehalfOfAccountId: null,
            createdBy: -1,
            createdOn: dayjs.tz(dayjs.utc(), 'UTC').tz(this.theUser.accountTimezone).format(mysqlFormat),
            createdByName: this.$t('Me')
          })

          this.newNote = ''
        })
        .catch(this.apiCatch)
        .finally(() => {
          this.$ui.progress.done()
          this.saving = false
        })
    },
    deleteNote(note) {
      this.$ui.progress.start()
      this.api.removeCampaignNote(this.campaignId, note)
        .then(() => {
          this.removeNoteFromList(note)
        })
        .catch(this.apiCatch)
        .finally(() => {
          this.$ui.progress.done()
        })
    },
    removeNoteFromList(note) {
      let noteIndex = -1
      this.notes.forEach((n, index) => {
        if (n.id === note.id) {
          noteIndex = index
        }
      })
      if (noteIndex > -1) {
        this.notes.splice(noteIndex, 1)
      } else {
        this.$ui.alert(this.$t('CampaignNotes.Could not find note to remove'))
      }
    }

  },
  components: {
    CampaignNotesManualNote,
    CampaignNotesSegment,
    CampaignNotesBasic,
    CampaignNotesOffer,
    CampaignNotesSegmentAction,
    CampaignNotesExperiment,
    ScrollShadow
  }
}
</script>

<style lang="scss">
#NotesModal {
  font-size: 14px;
  .chevron {
    float: right;
    padding-top: 18px;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
  }
  .FilterField {
    margin: 20px;
  }
  .Modal-notes {
    // height: 50vh;
    overflow: hidden;
    // overflow-y: scroll;
    .note-chevron {
      margin-left: 10px;
      cursor: pointer;
    }
    .delete {
      margin-left: 10px;
      margin-top: -2px;
      cursor: pointer;
      color: red;
    }

    .note {
      hr {
        width: 80%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .head {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 10px;
        border-top: 1px solid #ddd;
        .type {
          flex: 1;
          .note-radio {
            float: left;
          }
        }
        .note-info {
          color: #666;
          font-size: 80%;
          text-align: right;
        }
      }
      .details {
        padding: 15px;
        font-size: 110%;
        .rule:first-of-type {
          margin-top: 5px;
        }
        .rule {
          .delete-rule {
            margin-bottom: 1px;
            background: #fef0ef;
            border-left: 4px solid #f01800;
          }
          .new-rule {
            margin-bottom: 1px;
            background: #e9f3e9;
            border-left: 4px solid green;
          }
          .change-rule {
            margin-bottom: 1px;
            background: #edf6ff;
            border-left: 4px solid #026ebb;
          }
          .rule-text {
            font-size: 14px;
            padding: 8px 16px 8px 12px;
          }
        }

        .offer-list {
          margin-top: 5px;
        }

        .text-data:first-of-type {
          margin-top:0px;
        }
        .text-data {
          margin-top: 8px;
          .display-text-label {
            font-weight: bold;
          }
          .display-text:first-of-type {
            padding-top: 5px;
          }
          .display-text {
            font-size: 14px;
            padding: 0px 0px 5px 15px;
            &.strike-through {
              text-decoration: line-through;
            }
            .title {
              color: green;
            }
            .value {
              color: purple;
              &.empty {
                font-style: italic;
              }
            }
          }

          .delete-text {
            margin-top: 5px;
            margin-bottom: 1px;
            background: #fef0ef;
            border-left: 4px solid #f01800;
          }
          .new-text {
            margin-top: 5px;
            margin-bottom: 1px;
            background: #e9f3e9;
            border-left: 4px solid green;
          }
          .change-text {
            margin-top: 5px;
            margin-bottom: 1px;
            background: #edf6ff;
            border-left: 4px solid #026ebb;
          }
        }
      }
    }
    .slide-enter-active {
      transition-duration: 0.3s;
      transition-timing-function: ease-in;
    }

    .slide-leave-active {
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .slide-enter-to, .slide-leave {
      max-height: 100px;
      overflow: hidden;
    }
    .slide-enter, .slide-leave-to {
      overflow: hidden;
      max-height: 0;
    }
  }

  .footer {
    padding: 10px 15px;
    border-top: 1px solid #ccc;
  }
  .red {
    color: red;
  }

  .Modal-notes-none {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 120%;
    color: #666;
  }
  table.change-table {
    margin-top: 5px;
    // margin-left: -9px;
    font-size: 90%;
    border-radius: 0px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    td {
      padding: 3px;
      vertical-align: top;
      line-height: 22px;
      text-align: left;
    }
  }
  .manual-note-input-wrapper {
    display: flex;
    align-items: flex-start;
    .manual-note-input-textfield {
      flex: 1 1 auto;
    }
    .manual-note-input-button {
      flex: 0 1 auto;
      margin-left: 15px;
    }
  }
}
</style>
