<template>
  <div :class="$style.PhoneInput">
    <TextField
      ref="phoneInput"
      :label="$t('Mobile Number')"
      :placeholder="placeholder"
      :value="internalInputValue"
      :disabled="disabled"
      :readonly="readonly"
      @input="onChangeInput"
      :error="error"
      :help-text="helpText"
    >
      <template #left>
        <div :class="$style.Code">
          <Select
            ref="prefixSelect"
            :placeholder="$t('AccountProfile.chooseCode')"
            :options="prefixData"
            :value="value.prefix"
            :disabled="disabled"
            :readonly="readonly"
            @change="onChangePrefix"
          />
        </div>
      </template>
    </TextField>
  </div>
</template>

<script>
export default {
  name: 'PhoneInput',
  props: {
    /**
     * { prefix: string, localNumber: string}
     */
    value: Object,
    prefixData: Array,
    helpText: String,
    error: String,
    disabled: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      currentPattern: '',
      currentRemovePrefix: 0,
      placeholder: '',
      internalInputValue: '',
      internalPrefix: null,
      internalRawValue: null
    }
  },
  mounted() {
    this.$watch('value', () => {
      // check if value has actually changed
      const ln = this.value.localNumber || null
      const prefix = this.value.prefix || null
      if (ln !== this.internalRawValue || prefix !== this.internalPrefix) { 
        this.internalInputValue = ln || ''
        this.updatePattern(prefix)
      }
    }, { immediate: true, deep: true })
  },
  methods: {
    onKeydownInput(event) {
      if (!event) return
      const input = event.target;

      if (input.selectionStart !== input.selectionEnd) {
        return
      }

      if (
        this.currentPattern &&
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key) &&
        input.value.length === this.currentPattern.length
      ) {
        event.preventDefault()
      }
    },
    onChangeInput(value, event) {
      // a little hack to open the select on the '+' key
      if (value === '+') {
        this.$refs.prefixSelect.open()
      }

      // Apply the pattern
      this.internalInputValue = this.applyPattern(value, this.currentPattern)
      this.showOutput()

      const input = event && event.target
      if (input) {
        const originalCursorPosition = input && input.selectionStart || 0;
        input.value = this.internalInputValue
        // Calculate new cursor position
        this.cursorPosition = this.calculateCursorPosition(value, this.internalInputValue, originalCursorPosition);
        this.setCursorPosition(input, this.cursorPosition);
      }
    },
    applyPattern(text, pattern) {
      const digits = text.replace(/[^\d]/g, '').split('');
      if (!pattern) return digits.join('')
      let result = '';
      for (let i = 0, len = pattern.length; i < len; i++) {
        if (!digits.length) {
          break;
        }
        const char = pattern[i];
        if (char === '_') {
          result += digits.shift();
        } else {
          result += char;
        }
      }
      return result;
    },
    calculateCursorPosition(oldValue, newValue, oldPosition) {
      // If cursor was at the end, keep it at the end
      if (oldPosition === oldValue.length) {
        return newValue.length;
      }

      let newPosition = oldPosition;
      const addedCharsBeforeCursor = newValue.length - oldValue.length;
      // Adjust cursor position based on added formatting characters
      if (addedCharsBeforeCursor > 0) {
        for (let i = 0; i < oldPosition; i++) {
          if (newValue[i] !== oldValue[i]) {
            newPosition++;
          }
        }
      } else if (addedCharsBeforeCursor < 0) {
        // Handle backspace: move cursor back if a formatting character was removed
        for (let i = newPosition; i < newValue.length; i++) {
          if (newValue[i] !== oldValue[i]) {
            newPosition--;
            break;
          }
        }
      }

      return newPosition;
    },
    setCursorPosition(input, position) {
      input.setSelectionRange(position, position);
    },
    onChangePrefix(prefix) {
      this.internalInputValue = ''
      this.updatePattern(prefix)
      this.$refs.phoneInput.focus()
    },
    updatePattern(prefix) {
      const optionEl = this.prefixData.find(p => p.value === prefix);
      if (!optionEl) {
        this.internalPrefix = null
        this.currentPattern = ''
        this.currentRemovePrefix = 0
        this.placeholder = ''
        this.onChangeInput(this.internalInputValue)
        return;
      }
      // console.log('picked option', optionEl);
      // countryPrefixEl.innerHTML = optionEl.value;
      // countryPrefixEl.innerHTML = '+' + optionEl.dataset.dialingcode;
      
      this.currentPattern = optionEl.pattern || '';
      this.internalPrefix = prefix;
      this.currentRemovePrefix = optionEl.removeprefix || 0;
      
      this.placeholder = this.currentPattern.replace(/_/g, '5');

      this.onChangeInput(this.internalInputValue);
    },
    showOutput() {
      const val = this.internalInputValue
      let goodVal = val.replace(/[^0-9]/g, '')
      if (this.currentRemovePrefix) {
        goodVal = goodVal.slice(this.currentRemovePrefix)
      }
      const truthyValueNoPrefix = val && !this.internalPrefix
      const invalid = truthyValueNoPrefix
        || (this.currentPattern && val && val.length !== this.currentPattern.length)
      this.internalRawValue = goodVal || null
      this.$emit('update:value', {
        prefix: this.internalPrefix || null,
        localNumber: this.internalRawValue,
        isValid: !invalid
      })
    }
  }
}
</script>

<style lang="scss" module>
.Code {
  width: 150px;
}
</style>