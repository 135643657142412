<template>
  <div id="SiteRegister" class="Main-Content">

    <Page>
      <LoadingLayout v-if="loading" />

      <Layout v-else>
        <LayoutSection>
          <Card :title="$t('Register')">
            <CardSection>

              <div v-if="error" class="error">{{ error }}</div>

              <validation-observer v-slot="{ invalid }" slim>
                <FormLayout>

                  <validation-provider name="email" ref="emailProvider" immediate rules="required|email" v-slot="{ errors }">
                    <TextField
                      :label="$t('Email')"
                      v-model.trim="data.email"
                      ref="email"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <validation-provider name="password" immediate rules="required" v-slot="{ errors }">
                    <TextField
                      :label="$t('Password')"
                      v-model.trim="data.password"
                      type="password"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <validation-provider name="name" immediate rules="required" v-slot="{ errors }">
                    <TextField
                      :label="$t('Name')"
                      v-model.trim="data.name"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <validation-provider name="company" immediate rules="required" v-slot="{ errors }">
                    <TextField
                      :label="$t('Company')"
                      v-model.trim="data.company"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <validation-provider name="country" immediate rules="required" v-slot="{ errors }">
                    <Select
                      :label="$t('Country')"
                      v-model="data.country"
                      :options="countries"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <validation-provider name="phone" immediate v-slot="{ errors }">
                    <TextField
                      :label="$t('Phone')"
                      v-model.trim="data.phone"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <TimezonePicker
                    :label="$t('Timezone')"
                    v-model="data.tz"
                    :disabled="trying"
                  />

                  <validation-provider name="currency" immediate rules="required" v-slot="{ errors }">
                    <Select
                      :label="$t('Currency')"
                      v-model="data.currency"
                      :options="currencies"
                      :disabled="trying"
                    />
                  </validation-provider>

                  <validation-provider name="terms" ref="termsProvider" immediate v-slot="{ errors }">
                    <div class="terms-wrapper">
                      <input
                        v-model="data.terms"
                        type="checkbox"
                        ref="terms"
                        class="form-check-input"
                        id="check-terms"
                        :disabled="trying"
                      />
                      <label class="form-check-label" for="check-terms">
                        {{ $t('I agree with the Fanplayr') }} <a href="http://www.fanplayr.com/legal/" target="_blank">{{ $t('Terms & Conditions') }}</a>
                      </label>
                    </div>
                  </validation-provider>

                  <Button type="primary" @click="register" :disabled="invalid || trying" fullWidth>{{ $t('Register') }}</Button>

                </FormLayout>
              </validation-observer>

            </CardSection>
          </Card>
        </LayoutSection>
      </Layout>
    </Page>

  </div>
</template>

<script>

export default {
  name: 'SiteRegister',
  metaInfo() {
    return {
      title: `${this.$t('Register')}`,
    }
  },
  data () {
    return {
      loading: true,
      trying: false,
      error: '',
      countries: [],
      currencies: [],
      timezones: [],
      data: {
        email: '',
        password: '',
        name: '',
        company: '',
        country: '',
        phone: '',
        currency: '',
        tz: '',
        terms: false
      },
    }
  },
  mounted() {
    this.$ui.progress.start()

    Promise.all([this.api.getCountriesList(), this.api.getCurrenciesList()])
    .then(([countries, currencies]) => {
      this.countries = countries
      this.currencies = currencies

      this.loading = false
      this.$nextTick(() => {
        try {
          this.$refs.email.focus()
        } catch(e) {}
      })
    })
    .catch(this.apiCatch)
    .finally(() => {
      this.$ui.progress.done()
    })
  },
  methods: {
    async testRecaptcha() {
      this.$recaptcha('register').then(async (token) => {
        let success = await this.api.testRecaptcha(token)
        console.log(success)
      })
    },
    async register() {
      this.error = ''

      this.$ui.progress.start()
      this.trying = true

      if (await this.api.checkForEmail(this.data.email)) {
        this.$ui.alert({ title: this.$t('Email already exists') })
        // this.$refs.emailProvider.setErrors(['email.already.exists'])
        this.$ui.progress.done()
        this.trying = false
        return
      }

      if (!this.data.terms) {
        this.$refs.termsProvider.setErrors(['accept.terms'])
        this.$ui.progress.done()
        this.trying = false
        return
      }

      this.$recaptcha('register').then(async (token) => {
        this.data.token = token
        this.data.defaultLanguage = this.currentLocale || 'en'
        this.api.registerNewUser(this.data)
        .then(async () => {
          const loginResponse = await this.api.login(this.data.email, this.data.password)
          await this.processPostLogin(loginResponse)
          await this.loadExtraDetails()
          this.$router.push('/')
        })
        .catch(this.apiCatch)
        .finally(() => {
          this.$ui.progress.done()
          this.trying = false
        })
      })
    }
  },
}

</script>

<style lang="scss">
#SiteRegister {
  .terms-wrapper {
    margin-bottom: 15px;
    margin-top: 25px;
  }
}
</style>
