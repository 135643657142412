<template functional>
  <div id="CampaignNotesManualNote">
    <template v-if="typeof props.note.notes === 'string'">
      {{$options.methods.parseSimpleNote(props.note.notes)}}
    </template>
    <template v-else>
      {{$options.methods.parseSimpleNote(props.note.notes.note)}}
    </template>
  </div>
</template>

<script>
export default {
  name: 'CampaignNotesManualNote',
  props: {
    note: {
      type: Object,
      required: true
    },
  },
  methods: {
    parseSimpleNote(note){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      return returnNoteStr
    },
  }
}
</script>
