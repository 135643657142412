<template functional>
  <div id="CampaignNotesOffer">
    <template v-if="typeof props.note.notes === 'string'">
      {{$options.methods.parseSimpleNote(props.note.notes)}}
    </template>
    <template v-else-if="props.note.objectAction === 'CREATE'">
      {{parent.$t('CampaignNotes.addedIncentive', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectAction === 'DELETE'">
      {{parent.$t('CampaignNotes.deletedIncentive', { value: props.note.objectName })}}
    </template>
    <template v-else>
      <div v-if="props.note.notes['TEXTJSON']" class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes['TEXTJSON']" :key="index" class="text-data">
          <template v-if="$options.methods.getIsBothJson(noteInner)">
            <div class="display-text-label">{{parent.$t('CampaignNotes.displayUpdated')}}: {{$options.methods.getLanguageLabel(noteInner.newValue, parent.$t, props.languages)}}</div>
            <div v-if="$options.methods.diff(noteInner, 'display')" class="change-text">
              <div class="display-text strike-through">
                <span class="title">{{parent.$t('CampaignNotes.displayText')}}: </span>
                <span class="value">{{$options.methods.getOldValueJson(noteInner).display}}</span>
              </div>
              <div class="display-text">
                <span class="title">{{parent.$t('CampaignNotes.displayText')}}: </span>
                <span class="value">{{$options.methods.getNewValueJson(noteInner).display}}</span>
              </div>
            </div>
            <div v-if="$options.methods.diff(noteInner, 'minPurchase')" class="change-text">
              <div class="display-text strike-through">
                <span class="title">{{parent.$t('CampaignNotes.minimumPurchase')}}: </span> 
                <span :class="{ value: true, empty: !$options.methods.getOldValueJson(noteInner).minPurchase }">
                  {{$options.methods.getOldValueJson(noteInner).minPurchase || parent.$t('merch.empty')}}
                </span>
              </div>
              <div class="display-text">
                <span class="title">{{parent.$t('CampaignNotes.minimumPurchase')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getNewValueJson(noteInner).minPurchase }">
                  {{$options.methods.getNewValueJson(noteInner).minPurchase || parent.$t('merch.empty')}}
                </span>
              </div>
            </div>
            <div v-if="$options.methods.diff(noteInner, 'condition')" class="change-text">
              <div class="display-text strike-through">
                <span class="title">{{parent.$t('CampaignNotes.conditions')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getOldValueJson(noteInner).condition }">
                  {{$options.methods.getOldValueJson(noteInner).condition || parent.$t('merch.empty')}}
                </span>
              </div>
              <div class="display-text">
                <span class="title">{{parent.$t('CampaignNotes.conditions')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getNewValueJson(noteInner).condition }">
                  {{$options.methods.getNewValueJson(noteInner).condition || parent.$t('merch.empty')}}
                </span>
              </div>
            </div>
          </template>
          <template v-else-if="$options.methods.getNewValueJson(noteInner) && !$options.methods.getOldValueJson(noteInner)">
            <div class="display-text-label">{{parent.$t('CampaignNotes.displayLanguageAdded')}}: {{$options.methods.getLanguageLabel(noteInner.newValue, parent.$t, props.languages)}}</div>
            <div class="new-text">
              <div class="display-text">
                <span class="title">{{parent.$t('CampaignNotes.displayText')}}: </span>
                <span class="value">{{$options.methods.getNewValueJson(noteInner).display}}</span>
              </div>
              <div class="display-text">
                <span class="title">{{parent.$t('CampaignNotes.minimumPurchase')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getNewValueJson(noteInner).minPurchase }">
                  {{$options.methods.getNewValueJson(noteInner).minPurchase || parent.$t('merch.empty')}}
                </span>
              </div>
              <div class="display-text">
                <span class="title">{{parent.$t('CampaignNotes.conditions')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getNewValueJson(noteInner).condition }">
                  {{$options.methods.getNewValueJson(noteInner).condition || parent.$t('merch.empty')}}
                </span>
              </div>
            </div>
          </template>
          <template v-else-if="!$options.methods.getNewValueJson(noteInner) && $options.methods.getOldValueJson(noteInner)">
            <div class="display-text-label">{{parent.$t('CampaignNotes.displayLanguageRemoved')}}: {{$options.methods.getLanguageLabel(noteInner.oldValue, parent.$t, props.languages)}}</div>
            <div class="delete-text">
              <div class="display-text strike-through">
                <span class="title">{{parent.$t('CampaignNotes.displayText')}}: </span>
                <span class="value">{{$options.methods.getOldValueJson(noteInner).display}}</span>
              </div>
              <div class="display-text strike-through">
                <span class="title">{{parent.$t('CampaignNotes.minimumPurchase')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getOldValueJson(noteInner).minPurchase }">
                  {{$options.methods.getOldValueJson(noteInner).minPurchase || parent.$t('merch.empty')}}
                </span>
              </div>
              <div class="display-text strike-through">
                <span class="title">{{parent.$t('CampaignNotes.conditions')}}: </span>
                <span :class="{ value: true, empty: !$options.methods.getOldValueJson(noteInner).condition }">
                  {{$options.methods.getOldValueJson(noteInner).condition || parent.$t('merch.empty')}}
                </span>
              </div>
            </div>
          </template>

        </div>
      </div>
      <div v-if="props.note.notes['OTHER']" class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes['OTHER']" :key="index" class="simple-note-content">
          <template v-if="noteInner.newValue !== undefined">
            <div v-html="parent.$t('CampaignNotes.fromTo', {
              attr: $options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t),
              old: $options.methods.fixValue(noteInner.oldValue, parent.$t, noteInner.attribute, parent.$ui.format),
              new: $options.methods.fixValue(noteInner.newValue, parent.$t, noteInner.attribute, parent.$ui.format),
            })"/>
          </template>
          <template v-else-if="noteInner.newValue === undefined && noteInner.oldValue === undefined">
            {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {capitalize} from 'lodash'
export default {
  name: 'CampaignNotesOffer',
  props: {
    note: {
      type: Object,
      required: true
    },
    campaignId: {
      type: Number,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  methods: {
    diff(noteInner, key) {
      let oldValueJson = null
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch {
        return false
      }
      let newValueJson = null
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch {
        return false
      }
      return oldValueJson && newValueJson && oldValueJson[key] !== newValueJson[key]
    },
    getLanguageLabel(strValue, translationFunction, languages) {
      let valueJson = null
      try {
        valueJson = JSON.parse(strValue)
      } catch {
        return ''
      }
      if (valueJson.language === 'default') return translationFunction('Default')
      const langObj = (languages || []).find(l => l.value === valueJson.language)
      return langObj && langObj.label || '-'
    },
    parseSimpleNote(note){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      return returnNoteStr
    },
    parseAttributeForTrans(attribute, translationFunction) {
      return translationFunction(`${attribute}`)
    },
    fixValue(value, translationFunction, attribute = null, formatFunction) {
      if (value === null || value === undefined || value === '') {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'boolean') {
        return translationFunction(value ? 'filters.TRUE.label' : 'filters.FALSE.label')
      }
      if (typeof(value) === 'string' && attribute === 'Status') {
        return translationFunction(capitalize(value))
      }
      if (typeof(value) === 'string' && attribute === 'Timezone') {
        return translationFunction(`campaignEdit.${value}Timezone`)
      }
      if (typeof(value) === 'string' && ['Validity Period Start', 'Validity Period End'].includes(attribute)) {
        return formatFunction(value, 'dateTimeShort')
      }
      if (typeof(value) === 'object') {
        return JSON.stringify(value)
      }
      if (typeof(value) === 'string' && value.match(/19[0-9][0-9]-[0-9][0-9]-[0-9][0-9]/) && parseInt(value.substr(0, 4), 10) < 1980) {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      return value
    },
    getIsBothJson(noteInner) {
      let isBothJson = true
      try {
        JSON.parse(noteInner.oldValue)
      } catch(e) {
        isBothJson = false
      }
      try {
        JSON.parse(noteInner.newValue)
      } catch(e) {
        isBothJson = false
      }
      return isBothJson
    },
    getOldValueJson(noteInner) {
      let oldValueJson
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch (e) {
        return ''
      }
      return oldValueJson
    },
    getNewValueJson(noteInner) {
      let newValueJson
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch (e) {
        return ''
      }
      return newValueJson
    },
  }
}
</script>
