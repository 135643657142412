<template>
  <Layout>
    <LayoutSection>
      <Card v-if="card" :style=" page ? 'margin: 25px;' : '' ">
        <CardSection>
          <div style="float: right;">
            <Spinner size="small" />
          </div>
          <SkeletonDisplayText />
          <br />
          <SkeletonBodyText :lines="3" />
          <br />
          <SkeletonBodyText :lines="3" />
        </CardSection>
      </Card>
      <template v-else>
        <div style="float: right;">
          <Spinner size="small" />
        </div>
        <SkeletonDisplayText />
        <br />
        <SkeletonBodyText :lines="3" />
        <br />
        <SkeletonBodyText :lines="3" />
      </template>
    </LayoutSection>
  </Layout>
</template>

<script>
export default {
  props: {
    card: {
      type: Boolean,
      default: true,
      required: false
    },
    page: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>