<template>
  <div id="SiteTopNavWrap">
    <header id="SiteTopNav">

      <a href="https://fanplayr.com/">
        <img class="brand" src="/logo.svg" >
      </a>

      <Popper auto-hide placement="bottom-end" id="LanguageChooserButton">
        <div slot="trigger">
          <div :style="{ backgroundImage: `url(https://static.fanplayr.com/portal/assets/img/flags/${getFlag(defaultLanguage)}.svg)` }"></div>
        </div>
        <template #default="{hide}">
          <div id="LanguageChooser" class="site">
            <div
              v-for="lang in supportedLanguages"
              :key="lang.value"
              :value="lang.value"
              :class="{ selected: lang.value === defaultLanguage }"
              @click="chooseLanguage(lang.value, hide)"
              :style="{ backgroundImage: `url(https://static.fanplayr.com/portal/assets/img/flags/${getFlag(lang.value)}.svg)` }"
            >{{ lang.label }}</div>
          </div>
        </template>
      </Popper>

    </header>
  </div>
</template>

<script>
export default {
  name: 'SiteTopNav',
  data() {
    return {
      defaultLanguage: 'en'
    }
  },
  async mounted() {
    this.$watch('defaultLanguage', async (newVal) => {
      this.$ui.progress.start()
      await this.setLanguage(newVal)
      window.localStorage['portal-language'] = newVal
      this.$ui.progress.done()
    })

    this.eventHub.$on('changeLanguage', (lang) => {
      this.defaultLanguage = lang
    })

    const localeGuess = await this.api.getLocaleGuess()
    const langCode = window.localStorage['portal-language'] || localeGuess && localeGuess.languageCode
    if (langCode && this.supportedLanguages.find(l => l.value === langCode)) {
      this.defaultLanguage =  langCode
    } else {
      this.defaultLanguage = window.localStorage['portal-language'] || 'en'
    }
  },
  methods: {
    getFlag(value) {
      return value ? value.toUpperCase() : ''
    },
    chooseLanguage(lang, hide) {
      this.defaultLanguage = lang
      hide()
    }
  }
}
</script>

<style lang="scss">

#SiteTopNavWrap {
  background: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  @media screen and (max-width: 768px) {
    height: 50px;
  }
}

#SiteTopNav {
  display: flex;
  align-items: flex-start;
  height: 70px;
  background: #fff;
  color: #666;
  width: 100%;

  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(1162px - 30px);

  @media screen and (max-width: 768px) {
    height: 50px;
    padding-left: 5px;
    padding-right: 5px;
  }

  img.brand {
    height: 49px;
    padding-left: 15px;
    margin-top: 13px;

    @media screen and (max-width: 768px) {
      height: 28px;
    }
  }

  #LanguageChooserButton {
    display: flex;
    flex: 1 1 auto;
    margin-left: auto;

    max-width: 40px;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    font-size: 1.5em;

    div {
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      background-repeat: no-repeat;
    }
  }
}
#LanguageChooser {
  width: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 5px;
  div + div {
    margin-top: 2px;
  }
  & > div {
    border-radius: 5px;
    background-repeat: no-repeat;
    padding: 8px 15px 8px 35px;
    background-size: 18px;
    background-position-y: center;
    background-position-x: 5px;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
}
</style>