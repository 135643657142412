<template>
  <div id="SiteChangePassword" class="Main-Content">

    <Page>
      <Layout>
        <LayoutSection>
          <Card :title="$t('Change Password')">
            <CardSection>

              <div v-if="error" class="message error">{{ error }}</div>

              <FormLayout>
                <TextField
                  :label="$t('New Password')"
                  type="password"
                  v-model="newPassword"
                  :error="complexityError"
                />
                <TextField
                  :label="$t('Repeat New Password')"
                  type="password"
                  v-model="newPasswordRepeat"
                />
                <Button
                  @click="changePassword"
                  type="primary"
                  :disabled="complexityError || saving"
                >{{ $t('Change Password') }}</Button>
              </FormLayout>

            </CardSection>
          </Card>
            <Card v-if="complexityExists">
              <CardSection>
                {{$t('AccountSecurity.passwordFollowRequirements')}}
                <div class="complexity-list">
                  <List tight>
                    <ListItem v-if="complexitySettings.minimumLength">{{$t('AccountSecurity.minLengthVal', { value: complexitySettings.minimumLength })}}</ListItem>
                    <ListItem v-if="complexitySettings.requiresSymbol">{{$t('AccountSecurity.atLeastOneSymbolVal', { value: symbolsAllowed })}}</ListItem>
                    <ListItem v-if="complexitySettings.requiresNumber">{{$t('AccountSecurity.atLeastOneNumber')}}</ListItem>
                    <ListItem v-if="complexitySettings.requiresMixedCase">{{$t('AccountSecurity.atLeastOneMixedCase')}}</ListItem>
                  </List>
                </div>
              </CardSection>
            </Card>
        </LayoutSection>
      </Layout>
    </Page>

  </div>
</template>

<script>

import _ from 'lodash'

export default {
  name: 'SiteChangePassword',
  metaInfo() {
    return {
      title: `${this.$t('Change Password')}`,
    }
  },
  data () {
    return {
      loading: true,
      newPassword: '',
      newPasswordRepeat: '',
      saving: false,
      complexitySettings: null,
      complexityExists: false,
      symbolsAllowed: `~\`!@#$%^&*()-[]{}|_+=\\"':;<,>.?/`,
      symbolsRegex: /[~`!@#$%^&*()\-[\]{}|_+=\\"':;<,>.?/]/g,
      numberRegex: /[0-9]/g,
      upperCaseRegex: /[A-Z]/g,
      lowerCaseRegex: /[a-z]/g,
    }
  },
  mounted() {
    if (this.$route.params.lang) {
      const lang = this.$route.params.lang
      this.$nextTick(() => {
        this.eventHub.$emit('changeLanguage', lang)
      })
    }

    const { id, hash } = this.$route.params
    this.api.getSecurityPasswordComplexity(id, hash)
    .then((response) => {
      this.complexitySettings = response
      this.complexityExists = !_.isEmpty(this.complexitySettings)
      this.loading = false
    })
    .catch(this.apiCatch)
  },
  methods: {
    changePassword() {
      if (!this.newPassword.trim() || !this.newPasswordRepeat.trim()) {
        this.$ui.alert({
          title: 'Alert',
          content: this.$t('Password Required')
        })
        return        
      }
      if (this.newPassword !== this.newPasswordRepeat) {
        this.$ui.alert({
          title: 'Alert',
          content: this.$t('Passwords must match')
        })
        return
      }

      const { id, hash } = this.$route.params

      this.$ui.progress.start()
      this.saving = true
      this.api.changeForgottenPassword(id, hash, this.newPassword)
      .then(() => {
        this.$ui.toast({ title: this.$t('Password Updated') })
        this.$router.push('/site/login/')
      })
      .catch(this.apiCatch)
      .finally(() => {
        this.$ui.progress.done()
        this.saving = false
      })
    }
  },
  computed: {
    complexityError() {
      if (!this.complexityExists || !this.newPassword) return false
      if (this.complexitySettings.minimumLength > this.newPassword.length) {
        return this.$t('AccountSecurity.passwordDoesNotMeetMin')
      }
      if (this.complexitySettings.requiresSymbol) {
        const matches = this.newPassword.match(this.symbolsRegex)
        if (!matches) return this.$t('AccountSecurity.passwordDoesNotContainSymbol')
      }
      if (this.complexitySettings.requiresNumber) {
        const matches = this.newPassword.match(this.numberRegex)
        if (!matches) return this.$t('AccountSecurity.passwordDoesNotContainNumber')
      }
      if (this.complexitySettings.requiresMixedCase) {
        const upperCase = this.newPassword.match(this.upperCaseRegex)
        const lowerCase = this.newPassword.match(this.lowerCaseRegex)
        if (!upperCase || !lowerCase) return this.$t('AccountSecurity.passwordDoesNotContainMixedCase')
      }
      return false
    }
  }
}

</script>

<style lang="scss" scoped>
.message {
  padding: 5px;
}
.error {
  color: #c00;
  font-weight: bold;
  margin-bottom: 20px;
}
.complexity-list {
  padding-top: 8px;
}
</style>