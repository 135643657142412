<template>
  <Modal
    title="Choose an Account"
    :primaryAction="{
      label: 'Switch',
      onAction: confirm
    }"
    :secondaryActions="[{
      label: 'Cancel',
      onAction: cancel
    }]"
    :visible="visible"
    overflowVisible
  >
    <Select
      v-model="accountToSwitchToId"
      class="Modal-input"
      :options="accounts"
      :searchable="true"
    />
  </Modal>
</template>

<script>

export default {
  name: 'SwitchAccountModal',
  data() {
    return {
      visible: false,
      resolve: null,
      reject: null,
      accountToSwitchToId: null
    };
  },
  mounted() {
  },
  methods: {
    show() {
      this.accountToSwitchToId = this.theUser.onBehalfOfAccountId

      this.visible = true

      return new Promise((res, rej) => {
        this.resolve = res
        this.reject = rej
      })
    },
    onCancel() {
      this.cancel()
    },
    cancel() {
      this.visible = false
      this.resolve(false)
    },
    confirm() {
      if (!this.accountToSwitchToId) {
        return
      }
      this.visible = false
      this.resolve(this.accountToSwitchToId)
    }
  },
  computed: {
    accounts() {
      if (!this.theUser) {
        return []
      }
      let r = [
        { label: 'Agency Account', value: this.theUser.accountId }
      ]
      if (this.agencyAccounts) {
        for (let acc of this.agencyAccounts) {
          if (acc.id !== this.theUser.accountId) {
            r.push({ label: acc.name, value: acc.id })
          }
        }
      }
      return r
    }
  }
};
</script>
