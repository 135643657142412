import _ from 'lodash'
import {outsideActionSchedule, outsideValidityPeriod} from './CampaignValidationUtil'

export async function getFilterFromQuery(keyMap, defaultFilters, routeName) {
  let appliedFilters = {}
  if (this.$route.query) {
    Object.keys(this.$route.query).forEach((k) => {
      if (keyMap[k]) {
        if (this.$route.query[k] !== null && this.$route.query[k] !== undefined) {
          if (keyMap[k].type === 'string') {
            if (keyMap[k].isArray) {
              appliedFilters[k] = Array.isArray(this.$route.query[k]) 
                ? this.$route.query[k] 
                : [this.$route.query[k]]
            } else {
              appliedFilters[k] = this.$route.query[k]
            }
          } else if (keyMap[k].type === 'number') {
            if (keyMap[k].isArray) {
              appliedFilters[k] = Array.isArray(this.$route.query[k]) 
                ? this.$route.query[k].map(id => parseInt(id))
                : [parseInt(this.$route.query[k])]
            } else {
              appliedFilters[k] = parseInt(this.$route.query[k])
            }
          } else if (keyMap[k].type === 'boolean') {
            appliedFilters[k] = typeof this.$route.query[k] === 'boolean' ? !!this.$route.query[k] : this.$route.query[k] === 'true'
          }
        } else {
          appliedFilters[k] = null
        }
      }
    })
  }
  
  if (defaultFilters) {
    Object.keys(defaultFilters).forEach((k) => {
      if (!Object.keys(appliedFilters).includes(k)) {
        appliedFilters[k] = _.cloneDeep(defaultFilters[k])
      }
    })
  }
  await this.$router.replace({ name: routeName, query: appliedFilters})
    .catch(this.duplicateRouteCatch)
  return appliedFilters
}


// Widget Variant Map from Widget Info
export function buildWidgetVariantMap(widgetInfos) {
  return widgetInfos
    .reduce((obj, info) => {
      if (!obj[info.id]) {
        obj[info.id] = []
      }
      if (info.variants.length) {
        obj[info.id].push(...info.variants);
      }
      return obj
    }, {})
}

// ---------------------------------------
// Experiment Type Calculations

function getActiveActionsWithActiveOffers(actionIds, allActions, allOffers, campaignTz) {
  return (actionIds||[])
    .map(aId => {
      const action = allActions.find(a => a.id === aId)
      const activeOfferIds = (action && action.offerIds || [])
        .filter(oId => {
          const offer = allOffers.find(o => o.id === oId)
          return offer
            && !offer.isDisabled
            && !outsideValidityPeriod(offer, campaignTz)
        })
      return action ? {
        ...action,
        activeOfferIds
      } : null
    })
    .filter(action => {
      return action
        && action.status === 'active'
        && !outsideActionSchedule(action, campaignTz)
    })
}

export function inferExperimentType(actionIds, allActions, allOffers, campaignTz) {
  const activeActions = getActiveActionsWithActiveOffers(actionIds, allActions, allOffers, campaignTz)
  if (activeActions.length <= 1) return null

  let widgetMap = {}
  let variantMap = {}
  let offerMap = {}
  let otherDetailsMap = {}
  activeActions.forEach(action => {
    // build map of unique widget ids
    widgetMap[action.widgetId] = true
    // build map of unique variants
    variantMap[action.widgetVariant] = true
    // build map of unique offers (> 1 offer is invalid and therefore treated same no offers)
    const oIds = action.activeOfferIds
    if (oIds.length === 1) offerMap[oIds[0]] = true
    // build map of unique set of other widget-related action settings
    otherDetailsMap[JSON.stringify({
      widgetProductDisplayId: action.widgetProductDisplayId,
      widgetDelaySecs: action.widgetDelaySecs,
      widgetExitTrigger: action.widgetExitTrigger,
      onlyRequireTriggerOnce: action.onlyRequireTriggerOnce,
      widgetSensitivity: action.widgetSensitivity,
    })] = true
  })

  // console.log(
  //   {widgetMap},
  //   {variantMap},
  //   {offerMap},
  //   {otherDetailsMap},
  // )
  // detect type based on uniqueness of different elements of the action
  const rv = { type: 'mixed', other: Object.keys(otherDetailsMap).length > 1}
  if (
    Object.keys(widgetMap).length > 1 // VARIABLE: more than one unique widget
  ) {
    // widget testing
    rv.type = 'widget'
  } else if (
    Object.keys(widgetMap).length === 1 // CONSTANT: only one unique widget
    && Object.keys(variantMap).length > 1 // VARIABLE: more than one unique variant
    && Object.keys(offerMap).length <= 1 // CONSTANT: only 1 (or none) unique offers
  ) {
    // variant testing
    rv.type = 'variant'
  } else if (
    Object.keys(widgetMap).length === 1 // CONSTANT: only one unique widget
    && Object.keys(variantMap).length === 1 // CONSTANT: only one unique variant
    && Object.keys(offerMap).length > 1 // VARIABLE: more than one unique offer
  ) {
    // offer testing
    rv.type = 'offer'
  }
  return rv
}

