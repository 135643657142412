<template functional>
  <div id="CampaignNotesSegmentAction">
    <template v-if="typeof props.note.notes === 'string'">
      {{$options.methods.parseSimpleNote(props.note.notes, parent.$t)}}
    </template>
    <template v-else-if="props.note.objectAction === 'CREATE'">
      {{parent.$t('CampaignNotes.addedAction', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectAction === 'DELETE'">
      {{parent.$t('CampaignNotes.deletedAction', { value: props.note.objectName })}}
    </template>
    <template v-else>
      <div v-if="props.note.notes['OFFER']" class="note-group-section">
        <b>{{parent.$t('CampaignNotes.offersUpdated')}}</b>
        <List tight class="offer-list">
          <ListItem v-for="(noteInner, index) in props.note.notes['OFFER']" :key="index">
            <template v-if="!noteInner.newValue && !noteInner.oldValue">
              {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
            </template>
            <div v-else-if="noteInner.newValue">
              {{parent.$t('CampaignNotes.addedOfferToAction')}} <PageLink :url="$options.methods.getOfferUrl(noteInner.newValue, props.campaignId)" external>{{$options.methods.formatOffer(noteInner.newValue).label}}</PageLink>
              <Caption>{{$options.methods.formatOffer(noteInner.newValue).description}}</Caption>
            </div>
            <div v-else-if="noteInner.oldValue">
              {{parent.$t('CampaignNotes.removedOfferFromAction')}} <PageLink :url="$options.methods.getOfferUrl(noteInner.oldValue, props.campaignId)" external>{{$options.methods.formatOffer(noteInner.oldValue).label}}</PageLink>
              <Caption>{{$options.methods.formatOffer(noteInner.oldValue).description}}</Caption>
            </div>
          </ListItem>
        </List>
      </div>
      <div v-if="props.note.notes['OTHER']" class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes['OTHER']" :key="index" class="simple-note-content">
          <template v-if="noteInner.newValue !== undefined">
            <div v-html="parent.$t('CampaignNotes.fromTo', {
              attr: $options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t),
              old: $options.methods.fixValue(noteInner.oldValue, parent.$t, noteInner.attribute, parent.$ui.format),
              new: $options.methods.fixValue(noteInner.newValue, parent.$t, noteInner.attribute, parent.$ui.format),
            })"/>
          </template>
          <template v-else-if="noteInner.newValue === undefined && noteInner.oldValue === undefined">
            {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import OffersUtil from '../../classes/utils/OffersUtil'
import {dayjs} from '../../classes/utils/DateUtil'
import {capitalize} from 'lodash'
export default {
  name: 'CampaignNotesSegment',
  props: {
    note: {
      type: Object,
      required: true
    },
    campaignId: {
      type: Number,
      required: true
    }
  },
  methods: {
    parseSimpleNote(note){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      return returnNoteStr
    },
    getIsBothJson(noteInner) {
      let isBothJson = true
      try {
        JSON.parse(noteInner.oldValue)
      } catch(e) {
        isBothJson = false
      }
      try {
        JSON.parse(noteInner.newValue)
      } catch(e) {
        isBothJson = false
      }
      return isBothJson
    },
    getOldValueJson(noteInner) {
      let oldValueJson
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch (e) {
        return ''
      }
      return oldValueJson
    },
    getNewValueJson(noteInner) {
      let newValueJson
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch (e) {
        return ''
      }
      return newValueJson
    },
    parseAttributeForTrans(attribute, translationFunction) {
      if (attribute === 'Sensitivity') {
        return translationFunction(`creative.${attribute}`)
      }
      return translationFunction(`${attribute}`)
    },
    formatOffer(strOffer) {
      let offer = {}
      try {
        offer = JSON.parse(strOffer)
      } catch (e) {
        return {}
      }
      return OffersUtil.formatOffer(offer)
    },
    getOfferUrl(strOffer, campaignId) {
      let offer = {}
      try {
        offer = JSON.parse(strOffer)
      } catch (e) {
        return ''
      }
      return `/#/campaigns/${campaignId}/offers/edit/${offer.id}`
    },
    fixValue(value, translationFunction, attribute, formatFunction) {
      if (value === null || value === undefined || value === '') {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'boolean') {
        return translationFunction(value ? 'filters.TRUE.label' : 'filters.FALSE.label')
      }
      if (typeof(value) === 'string' && attribute === 'Widget' && value === 'Default') {
        return translationFunction('Default')
      }
      if (typeof(value) === 'string' && attribute === 'Days') {
        try {
          return JSON.parse(`[${value}]`).map(i => translationFunction(daysOfWeek[i])).join(', ')
        } catch { return `<i>${translationFunction('merch.empty')}</i>`; }
      }
      if (typeof(value) === 'string' && attribute === 'Timezone') {
        return translationFunction(`campaignEdit.${value}Timezone`)
      }
      if (typeof(value) === 'string' && attribute === 'Sensitivity') {
        return translationFunction(`creative.sensitivity.${value}`)
      }
      if (typeof(value) === 'string' && ['Status', 'Priority'].includes(attribute)) {
        return translationFunction(capitalize(value))
      }
      if (typeof(value) === 'string' && ['Start Date', 'End Date'].includes(attribute)) {
        return formatFunction(value, 'dateTimeShort')
      }
      if (typeof(value) === 'string' && ['Start Time', 'End Time'].includes(attribute)) {
        return dayjs(value, ['HH:mm:ss']).format('LT')
      }
      if (typeof(value) === 'object') {
        return JSON.stringify(value)
      }
      return value
    },
  }
}
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]
</script>
