export default class Env {
  static isDevelopment() {
    return window.location.hostname.indexOf('localhost') > -1
  }
  static isDevelopmentLive() {
    return window.location.hostname.indexOf('dev-portal.fanplayr.com') > -1
  }
  static isStaging() {
    return window.location.hostname.indexOf('stage.fanplayr.com') > -1
  }
  static isProduction() {
    return window.location.hostname.indexOf('portal.fanplayr.com') > -1 || window.location.hostname.indexOf('portal2.fanplayr.com') > -1
  }

  static getEnvironment() {
    if (this.isDevelopment() || this.isDevelopmentLive()) return 'dev'
    if (this.isStaging()) return 'stage'
    if (this.isProduction()) return 'prod'
    return null
  }
}