export default [{
  id: 'macos',
  name: 'MacOS',
  browsers: [{
    id: 'macos-chrome',
    name: 'Chrome'
  },
  {
    id: 'macos-firefox',
    name: 'Firefox'
  }]
},
{
  id: 'android',
  name: 'Android',
  browsers: [{
    id: 'android-chrome',
    name: 'Chrome'
  },
  {
    id: 'android-firefox',
    name: 'Firefox'
  }]
},
{
  id: 'windows',
  name: 'Windows',
  browsers: [{
    id: 'windows-chrome',
    name: 'Chrome'
  },
  {
    id: 'windows-firefox',
    name: 'Firefox'
  },
  {
    id: 'windows-edge',
    name: 'Edge'
  }]
}]
