const defaultRelativeShortcuts = [
  {type: 'relative', unit: 'day', value: 0},
  {type: 'relative', unit: 'day', value: 1},
  {type: 'relative', unit: 'day', value: 7},
  {type: 'relative', unit: 'day', value: 30},
  {type: 'relative', unit: 'day', value: 90},
  {type: 'relative', unit: 'day', value: 365}
]

const PortalI18n = window.PortalI18n
/**
 * 
 * {
 *   serviceId: 'automations'|'audiences'|'insights'|'exports',
 *   includeAllShortcuts: boolean,
 *   automationTriggerType: 'EVENT'|'SCHEDULED'
 * }
 */
export function getDatePeriodOptions(opts) {
  const options = {
    locale: PortalI18n.currentLocale || 'en',
    shortcuts: [
      ...defaultRelativeShortcuts
    ],
    customLabels: {},
    maxRangeDays: 0
  }
  if (opts && (opts.serviceId === 'automations' || opts.includeAllShortcuts)) {
    options.shortcuts.push({
      type: 'custom',
      id: 'sinceLastAutomationStep'
    })
    if (opts.automationTriggerType === 'EVENT' || opts.includeAllShortcuts) {
      options.shortcuts.push({
        type: 'custom',
        id: 'triggeringEvent'
      })
      options.customLabels.triggeringEvent = PortalI18n.translate('cdp.triggeringEvent')
    }
    options.customLabels.sinceLastAutomationStep = PortalI18n.translate('cdp.sinceLastAutomationStep')
    // only 30 days of history max in automations (unless include all)
    options.maxRangeDays = opts.includeAllShortCuts ? 0 : 30
  }
  return options
}

export function getAllDatePeriodOptions() {
  return getDatePeriodOptions({ includeAllShortcuts: true })
}
