<template>
  <Modal
    :title="$t('Image Library')"
    :primaryAction="{
      label: $t('Choose'),
      onAction: choose,
      disabled: !selectedImage
    }"
    :secondaryActions="[
      {
        label: $t('Cancel'),
        onAction: () => visible = false
      }
    ]"
    @close="visible = false"
    :visible="visible"
  >
    <LoadingLayout v-if="loading" />
    <div
      v-else
      class="library"

      @mouseleave="stopDrag"
      @dragend="stopDrag"
      @dragover="onDragOver"
      @drop="onDrop"
    >
      <div v-if="isDragging" class="dropper">{{$t('Drop images')}} ...</div>
      <div v-show="!loading" class="upload" @click="promptUpload">
        <div v-if="uploadingImages" class="upload-status">{{$t('Uploading')}} ...</div>
        <div class="upload-icon"></div>
        <div class="upload-message">
          <div class="upload-title">{{$t('Upload Image')}}</div>
          <div class="upload-hint">{{$t('ImageLibrary.Or drag and drop to upload')}}</div>
        </div>
        <form ref="form">
          <input class="fileInput" type="file" name="file" multiple ref="fileInput" @change="processFileInput">
        </form>
      </div>
      <div v-if="images.length === 0" class="no-images">{{$t('ImageLibrary.noImagesMessage')}}</div>
      <div v-if="images.length" class="images">
        <div
          v-for="image in images"
          :key="image.id"
          @click="selectedImage = image"
          @dblclick="selectedImage = image; choose();"
          :class="{ 'image--selected': selectedImage == image }"
          class="image"
          :style="{ 'background-image': `url('${image.cdnUrl}')` }"
        >
          <div class="delete" @click="deleteImage(image)">✕</div>
          <div class="dimensions">{{ image.width }}px x {{ image.height }}px</div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>

const ALLOWED_MIME_TYPES = [
  'image/png',
  'image/gif',
  'image/jpeg'
];
// 'image/svg+xml'

// 500 kb
const MAX_FILE_SIZE = 1024 * 500;

export default {
  name: 'ImageLibraryModal',
  data() {
    return {
      loading: true,
      images: [],
      error: '',
      resolve: null,
      reject: null,
      visible: false,
      selectedImage: null,
      isDragging: false,
      uploadingImages: false
    }
  },
  methods: {
    show(callbackFunc) {
      this.loading = true
      this.images = []
      this.isDragging = false
      this.uploadingImages = false

      this.visible = true
      this.callbackFunc = callbackFunc || null
      this.selectedImage = null

      // start loading
      this.api.getImages()
      .then((response) => {
        this.images = response.images
        this.loading = false
      })
      .catch(this.apiCatch)
      .finally(() => {
      })

      return new Promise((res, rej) => {
        this.resolve = res
        this.reject = rej
      })
    },
    choose() {
      this.visible = false
      if (this.callbackFunc) {
        this.callbackFunc(JSON.parse(JSON.stringify(this.selectedImage)))
      } else {
        this.resolve(JSON.parse(JSON.stringify(this.selectedImage)))
      }
    },
    async deleteImage(image) {
      const conf = await this.$ui.confirm({ title: this.$t('Are you sure?') })
      if (conf) {
        this.$ui.progress.start()
        this.api.removeImage(image.id)
          .then(() => {
            this.images.splice(this.images.findIndex(o => o.id === image.id), 1)
          })
          .catch(this.apiCatch)
          .finally(() => {
            this.$ui.progress.done()
          })
      }
    },
    processFileInput: function () {
      this.uploadFiles(this.$refs.fileInput.files)
    },
    dropFiles: function ( files ) {
      this.uploadFiles(files)
    },
    onDragOver: function ( event ) {
      event.preventDefault()
      if (!this.uploadingImages) {
        this.isDragging = true
      }
    },
    onDrop: function ( event ) {
      event.preventDefault();
      if (!this.uploadingImages) 
        this.uploadFiles(event.dataTransfer.files)
        this.stopDrag()
    },
    stopDrag: function () {
      this.isDragging = false
    },
    promptUpload: function () {
      if (!this.uploadingImages) {
        this.$refs.fileInput.click()
      }
    },
    uploadFiles: function ( files ) {
      var overMaxSizeCount = 0
      var invalidTypeCount = 0
      var validCount = 0

      var formData = new FormData()

      Array.from(files).forEach(( file, index ) => {
        if ( ALLOWED_MIME_TYPES.indexOf(file.type) >= 0 ) {
          if ( file.size < MAX_FILE_SIZE ) {
            formData.append('file' + index, file)
            validCount++
          } else {
            overMaxSizeCount++
          }
        } else {
          invalidTypeCount++
        }
      })

      formData.append('count', validCount);

      if ( validCount ) {
        this.uploadingImages = true;
        this.$ui.progress.start()
        this.api.uploadImages(formData)
          .then((response) => {
            for(let i=0; i<response.images.length; i++) {
              this.images.push(response.images[i])
            }
            this.uploadingImages = false
          })
          .catch((error) => {
            this.$ui.alert({ title: error.message })
          })
          .finally(() => {
            this.$ui.progress.done()
          })
      }

      if ( overMaxSizeCount ) {
        this.$ui.alert({ title: this.$t('File too big') })
      }

      if ( invalidTypeCount ) {
        this.$ui.alert({ title: this.$t('Invalid file type') })
      }

      this.stopDrag();
    }
  }
}
</script>

<style lang="scss" scoped>
.no-images {
  margin-top: 50px;
  text-align: center;
}
.library {
  height: 400px;
  position: relative;
  .dropper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #000;
    font-size: 300%;
    z-index: 1;
    text-align: center;
    padding-top: 180px;
  }
}
.images {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  padding-top: 5px;
  height: 320px;
  .image {
    width: 130px;
    height: 130px;
    margin: 5px;
    display: inline-block;
    background-color: #eee;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 5px solid #ccc;
    position: relative;
    cursor: pointer;
    .dimensions {
      display: none;
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 5px;
      text-align: center;
      color: #000;
    }
    &:hover {
      .dimensions, .delete  {
        display: block;
      }
    }
    &--selected {
      border-color: #000;
      & .dimensions, & .delete {
        display: block;
      }
    }
    .delete {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      text-align: center;
      color: #fff;
      background: #000;
      border-radius: 50%;
    }
  }
}
.upload {
  height: 80px;
  background: #f5f5f5;
  border-bottom: 1px solid #eee;
  text-align: center;
  position: relative;
  cursor: pointer;
  form {
    display: none;
  }
  &-status {
    position: absolute;
    height: 120px;
    width: 100%;
    color: #fff;
    background: #448aff;
    padding-top: 50px;
    font-size: 120%;
    z-index: 1;
  }
  &-icon {
    width: 50px;
    height: 50px;
    background: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221%22%20viewBox%3D%220%20-256%201950%201950%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M1303%20756q0-14-9-23L942%20381q-9-9-23-9t-23%209L545%20732q-10%2012-10%2024%200%2014%209%2023t23%209h224v352q0%2013%209%2023%2010%209%2023%209h192q13%200%2022-9%2010-10%2010-23V788h224q13%200%2022-9%2010-10%2010-23zm640%20288q0%20159-113%20272-112%20112-271%20112H471q-185%200-317-131Q23%201165%2023%20980q0-130%2070-240t188-165l-2-43q0-212%20150-362T791%2020q156%200%20285%2087%20130%2087%20189%20231%2071-62%20166-62%20106%200%20181%2075t75%20181q0%2076-41%20138%20130%2031%20213%20136%2084%20104%2084%20238z%22%2F%3E%3C%2Fsvg%3E) center center no-repeat;
    display: inline-block;
    margin-top: 21px;
    position: relative;
  }
  &-message {
    display: inline-block;
    text-align: left;
    margin-left: 15px;
    height: 70px;
    position: relative;
    top: -16px;
  }
}
</style>