<template>
  <div class="Main-Inner">
    <div class="Main-Content padding">
      <LoadingLayout />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Insights',
  mounted() {
    if (!this.theUser.isSmartEnabled) {
      if (this.theUser.isCdpEnabled) {
        this.$router.replace('/cdp')
      } else if (this.theUser.privacyIdEnabled) {
        this.$router.replace('/privacy-id')
      }
    } else {
      window.location = '/insights/'
    }
  }
}
</script>

<style scoped>
  .padding {
    padding-top: 32px;
  }
</style>