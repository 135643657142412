import UI from 'fanplayr-ui'
import PortalI18n from 'PortalI18n'
export default class OffersUtil {

  static fillForFrontend(offers, summarySettings = null) {
    return offers.map(offer => {
      // console.log(JSON.stringify(offer))
      offer.code = offer.promoCode
      if (offer.promoType === 'file') {
        offer.code = PortalI18n.translate('campaignEdit.offer.Multiple Codes')
      }
      offer.rawValue = offer.value
      offer.value = offer.id
      offer.label = offer.name

      offer.description = this.getSummary(offer, summarySettings || {
        id: true,
        status: false,
        display: true,
        promoCode: true,
        condition: false,
        validity: true,
        minPurchase: true
      })
      return offer
    })
  }
  
  static formatOffer(offer, summarySettings = null) {
    if (!offer) return null
    const offers = this.fillForFrontend([offer], summarySettings)
    return offers && offers.length ? offers[0] : {}
  }

  static getSummary(offer, options) {
    const parts = []
    if (options.id) {
      parts.push(`#${offer.id}`)
    }
    if (options.display) {
      parts.push(this.getDefaultDisplay(offer))
    }
    if (options.promoCode) {
      parts.push(PortalI18n.translate('campaignEdit.offer.codeLabel', { value: offer.code }))
    }
    if (options.validity) {
      if (offer.validFrom && offer.validUntil) {
        parts.push(PortalI18n.translate('campaignEdit.offer.fromXtoYLabel', 
          { value1: UI.format(offer.validFrom, 'dateShort'), value2: UI.format(offer.validUntil, 'dateShort') }))
      } else if (offer.validFrom) {
        parts.push(PortalI18n.translate('campaignEdit.offer.fromXLabel', { value: UI.format(offer.validFrom, 'dateShort') }))
      } else if (offer.validUntil) {
        parts.push(PortalI18n.translate('campaignEdit.offer.untilXLabel', { value: UI.format(offer.validUntil, 'dateShort') }))
      }
    }
    if (options.minPurchase) {
      const min = this.getDefaultMinPurchase(offer)
      parts.push(min ? `${PortalI18n.translate(`campaignEdit.offer.minimum`)}: ${min}` : PortalI18n.translate('campaignEdit.offer.noMinimum'))
    }
    if (options.condition) {
      const condition = this.getDefaultCondition(offer)
      parts.push(condition || PortalI18n.translate('campaignEdit.offer.noConditions'))
    }
    if (options.status) {
      const status = !offer.isDisabled ? PortalI18n.translate('Active') : PortalI18n.translate('Disabled')
      parts.push(`${PortalI18n.translate('Status')}: ${status}`)
    }
    // console.log(parts)
    return parts.join(' • ')
  }

  static getDefaultMinPurchase(offer) {
    return offer 
      && offer.textJson
      && offer.textJson['default']
      && offer.textJson['default'].minPurchase
      || ''
  }

  static getDefaultDisplay(offer) {
    return offer 
      && offer.textJson
      && offer.textJson['default']
      && offer.textJson['default'].display
      || ''
  }

  static getDefaultCondition(offer) {
    return offer 
      && offer.textJson
      && offer.textJson['default']
      && offer.textJson['default'].condition
      || ''
  }
}