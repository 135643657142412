// import _ from 'lodash'
import OsBrowser from '../data/OsBrowser'
import UnauthenticatedError from '../../../classes/errors/UnauthenticatedError'
import { dayjs } from '../../../classes/utils/DateUtil'

export default class Api {
  static inst
  static get(sessionKey) {
    if (!this.inst) {
      Api.inst = new Api(sessionKey)
    }
    return Api.inst
  }
  constructor() {
    this.endpoint = 'https://api.fanplayr.com/notifications'
  }
  setToken(sessionKey) {
    this.sessionKey = sessionKey
  }
  // ---------------------------------------------------
  // notifications
  getNotifications(startDate, endDate, includeStatistics = false) {
    return this.makeRequest('notifications', 'query', {}, `&noDefinition=true&startDate=${startDate}&endDate=${endDate}&includeStatistics=${includeStatistics}`)
  }
  getNotification(id) {
    return this.makeRequest('notifications', 'query', {
      notificationId: id
    })
  }
  createNotification(name, type, defaultIcon) {
    if (!['manual', 'template'].includes(type)) {
      throw new Error(`Invalid type for notification: ${type}`)
    }
    const osBrowserCombinations = {}
    OsBrowser.forEach((os) => {
      osBrowserCombinations[os.id] = os.browsers.map(browser => browser.id.split('-')[1])
    })
    const notificationDef = {
      name,
      status: 'draft',
      type,
      segments: {
        browserLanguage: ['any'],
      }
    }
    // used by some
    notificationDef.definition = {
      productType: ''
    }
    // woot!
    if (defaultIcon) {
      notificationDef.definition.icon = defaultIcon
    }

    // we want all browsers chosen by default
    notificationDef.segments.osBrowserCombinations = osBrowserCombinations

    if (type === 'manual') {
      notificationDef.segments.startDate = dayjs().subtract(1, 'years').format('YYYY-MM-DD 00:00:00')
      notificationDef.segments.endDate = dayjs().format('YYYY-MM-DD 00:00:00')
    }
    return this.makeRequest('notifications', 'update', notificationDef)
  }
  updateNotification(notification) {
    return this.makeRequest('notifications', 'update', notification)
  }
  getDashboardCount() {
    return this.makeRequest('notifications', 'dashboardCount', {})
  }
  // deleteNotification(id) {
  deleteNotification(id) {
    return this.makeRequest('notifications', 'delete', {
      notificationId: id
    })
  }
  //
  sendTestNotification(notification, userKey) {
    return this.makeRequest('notifications', 'sendPreview', notification, `&userKey=${userKey}`)
  }
  //
  pauseNotification(id) {
    return this.getNotification(id)
      .then((response) => {
        if (response && response.notifications) {
          const notification = response.notifications[0]
          notification.status = 'paused'
          return this.makeRequest('notifications', 'update', notification)
        }
        throw new Error('Could not get Notification to pause')
      })
  }
  activateNotification(id) {
    return this.getNotification(id)
      .then((response) => {
        if (response && response.notifications) {
          const notification = response.notifications[0]
          notification.status = 'active'
          return this.makeRequest('notifications', 'update', notification)
        }
        throw new Error('Could not get Notification to activate')
      })
  }
  copyNotification(id, newName) {
    return this.getNotification(id)
      .then((response) => {
        if (response && response.notifications) {
          const newNotification = response.notifications[0]
          newNotification.notificationId = null
          newNotification.name = newName
          newNotification.status = 'draft'

          // update dates to 1 year - like new one
          newNotification.segments.startDate = dayjs().subtract(1, 'years').format('YYYY-MM-DD 00:00:00')
          newNotification.segments.endDate = dayjs().format('YYYY-MM-DD 00:00:00')

          // remove scheduling
          newNotification.schedule = null
          newNotification.sentOn = null

          return this.makeRequest('notifications', 'update', newNotification)
        }
        throw new Error('Could not get Notification to copy')
      })
  }
  makeDraftFromErrored(id) {
    return this.getNotification(id)
      .then((response) => {
        if (response && response.notifications) {
          const newNotification = response.notifications[0]
          newNotification.status = 'draft'

          return this.makeRequest('notifications', 'update', newNotification)
        }
        throw new Error('Could not get Notification to make draft')
      })
  }
  // ---------------------------------------------------
  // triggered notifications
  getTriggeredNotifications() {
    return this.makeRequest('triggered-notifications', 'query')
  }
  getTriggeredNotification(id) {
    return this.makeRequest('triggered-notifications', 'query', {
      notificationId: id
    })
  }
  createTriggeredNotification(name, type) {
    if (!['abandonedCart', 'productReminder', 'inactiveUser', 'orderConfirmation', 'priceChange'].includes(type)) {
      throw new Error(`Invalid type for notification: ${type}`)
    }
    return this.makeRequest('triggered-notifications', 'update', {
      name,
      status: 'draft',
      type
    })
  }
  getNotificationExecutionDetails(id) {
    // http://api.fanplayr.com/notifications?type=notifications&action=executionDetails&notificationId=5291
    return this.makeRequest('notifications', 'executionDetails', {}, `&notificationId=${id}`)
      .then((response) => {
        if (response && response.notifications && response.notifications[0]) {
          return response.notifications[0]
        }
        throw new Error('Unable to parse response')
      })
  }
  // ---------------------------------------------------
  // settings
  getSettings() {
    return this.makeRequest('settings', 'query')
  }
  updateSettings(settings) {
    return this.makeRequest('settings', 'update', settings)
  }
  async makeRequest(type, action, body = {}, extraUrl = '') {
    if (!this.sessionKey) {
      throw new Error('API: Missing session key')
    }
    const url = `${this.endpoint}?type=${type}&action=${action}&siteId=0${extraUrl}&apiVersion=2`
    const response = await fetch(url, {
      headers: {
        'x-auth-key': this.sessionKey
      },
      mode: 'cors',
      method: 'post',
      body: JSON.stringify(body),
    })
    if (response.ok) {
      const json = await response.json()
      if (json.status === 'error') {
        throw new Error(json.message)
      }
      return json
    } else if (response.status === 401) {
      throw new UnauthenticatedError()
    }
    throw new Error(response.statusText);
  }
}
