import { ConfigElement } from "./types"

// IMPORTANT: Make sure to keep in sync with `ConversionUtil.js` (getConversionRuleRenderMap)
const data:Array<ConfigElement> = [
  {
    id: "ANY_ORDER",
    type: "BASE",
    label: "ConversionDefinitions.anyOrder",
    description: "ConversionDefinitions.anyOrderHelp"
  },
  {
    id: "FANPLAYR_ORDER",
    type: "BASE",
    label: "ConversionDefinitions.fanplayrOrderV2",
    description: "ConversionDefinitions.fanplayrOrderHelp"
  },
  {
    id: "NON_FANPLAYR_ORDER",
    type: "BASE",
    label: "ConversionDefinitions.nonFanplayrOrderV2",
    description: "ConversionDefinitions.nonFanplayrOrderHelp"
  },
  {
    id: "FORM_SUBMIT",
    type: "VARCHAR",
    disabled: true,
    label: "ConversionDefinitions.formSubmit",
    description: "ConversionDefinitions.formSubmitHelp",
    operators: [
      { value: 'EQUALS' }
    ]
  },
  {
    id: "EMAIL_COLLECTION",
    type: "VARCHAR",
    disabled: true,
    label: "ConversionDefinitions.emailSubmit",
    description: "ConversionDefinitions.emailSubmitHelp",
    operators: [
      { value: 'EQUALS'}
    ] 
  },
  {
    id: "WIDGET_VIEW",
    type: "VARCHAR",
    disabled: true,
    label: "ConversionDefinitions.widgetView",
    description: "ConversionDefinitions.widgetViewHelp",
    operators: [
      { value: 'EQUALS' }
    ]
  },
  {
    id: "FORM_SUBMIT_ID",
    type: "ENUM",
    optionProvider: 'widget',
    valueInputLabel: 'Widgets',
    valuePlaceholder: 'filters.anyWidget',
    hideOperatorsInEditor: true,
    label: "ConversionDefinitions.formSubmitId",
    description: "ConversionDefinitions.formSubmitIdHelp",
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "EMAIL_COLLECTION_ID",
    type: "ENUM",
    optionProvider: 'widget',
    valueInputLabel: 'Widgets',
    valuePlaceholder: 'filters.anyWidget',
    hideOperatorsInEditor: true,
    label: "ConversionDefinitions.emailSubmitId",
    description: "ConversionDefinitions.emailSubmitIdHelp",
    operators: [
      { value: 'IN', split: true }
    ] 
  },
  {
    id: "WIDGET_VIEW_ID",
    type: "ENUM",
    optionProvider: 'widget',
    valueInputLabel: 'Widgets',
    valuePlaceholder: 'filters.anyWidget',
    hideOperatorsInEditor: true,
    label: "ConversionDefinitions.widgetViewId",
    description: "ConversionDefinitions.widgetViewIdHelp",
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "WIDGET_INTERACTION",
    type: "VARCHAR",
    disabled: true,
    label: "ConversionDefinitions.widgetInteraction",
    description: "ConversionDefinitions.widgetInteractionHelp",
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "WIDGET_INTERACTION_MATCHES",
    type: "VARCHAR",
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    label: "ConversionDefinitions.widgetInteractionMatches",
    description: "ConversionDefinitions.widgetInteractionMatchesHelp",
    valueInputLabel: 'filters.components',
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "WIDGET_INTERACTION_DOES_NOT_MATCH",
    type: "VARCHAR",
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    label: "ConversionDefinitions.widgetInteractionDoesNotMatch",
    description: "ConversionDefinitions.widgetInteractionDoesNotMatchHelp",
    valueRequired:true,
    valueInputLabel: 'filters.components',
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "SAAS_TRIGGERED",
    type: "ENUM",
    label: "ConversionDefinitions.segmentTriggered",
    description: "ConversionDefinitions.segmentTriggeredV2Help",
    hideOperatorsInEditor: true,
    optionProvider: 'tag',
    valuePlaceholder: 'filters.anyTag',
    valueInputLabel: 'merch.tags',
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "ANY_PAGE_URL_CONTAINS",
    type: "VARCHAR",
    label: "ConversionDefinitions.anyPageUrlMatches",
    description: "ConversionDefinitions.anyPageUrlContainsV2Help",
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    valueRequireNoSpaces: true, 
    valueRequired: true,
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "SOURCE_WEB_PUSH",
    type: "BASE",
    label: "ConversionDefinitions.webPushIsSource",
    description: "ConversionDefinitions.webPushIsSourceHelp"
  },
  {
    id: "WEB_PUSH_CLICK",
    label: "ConversionDefinitions.webPushClick",
    type: "BASE",
    description: "ConversionDefinitions.webPushClickHelp"
  },
  {
    id: "ORDER_NUMBER_MATCHES",
    type: "VARCHAR",
    label: "ConversionDefinitions.orderNumberMatches",
    description: "ConversionDefinitions.orderNumberMatchesV2Help",
    valueRequired: true,
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "ORDER_DISCOUNT_CODE_MATCHES",
    type: "VARCHAR",
    label: "ConversionDefinitions.orderDiscountCodeMatches",
    description: "ConversionDefinitions.orderDiscountCodeMatchesV2Help",
    valueRequired: true,
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "ORDER_DISCOUNT_CODE_DOES_NOT_MATCH",
    type: "VARCHAR",
    label: "ConversionDefinitions.orderDiscountCodeDoesNotMatch",
    description: "ConversionDefinitions.orderDiscountCodeDoesNotMatchV2Help",
    valueRequired: true,
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    operators: [
      { value: 'IN', split: true }
    ]
  },
  {
    id: "ORDER_GROSS_LESS_THAN",
    label: "ConversionDefinitions.orderGrossLessThan",
    type: "DECIMAL",
    description: "ConversionDefinitions.orderGrossLessThanHelp",
    valueRequired: true,
    isCurrency: true,
  },
  {
    id: "ORDER_NET_LESS_THAN",
    type: "DECIMAL",
    label: "ConversionDefinitions.orderNetLessThan",
    description: "ConversionDefinitions.orderNetLessThanHelp",
    valueRequired: true,
    isCurrency: true,
  },
  {
    id: "ORDER_GROSS_GREATER_THAN_EQUALS",
    type: "DECIMAL",
    label: "ConversionDefinitions.orderGrossGreaterThanEquals",
    description: "ConversionDefinitions.orderGrossGreaterThanEqualsHelp",
    valueRequired: true,
    isCurrency: true,
  },
  {
    id: "ORDER_NET_GREATER_THAN_EQUALS",
    type: "DECIMAL",
    label: "ConversionDefinitions.orderNetGreaterThanEquals",
    description: "ConversionDefinitions.orderNetGreaterThanEqualsHelp",
    valueRequired: true,
    isCurrency: true,
  },
  {
    id: "ORDER_DISCOUNT_LESS_THAN",
    type: "DECIMAL",
    label: "ConversionDefinitions.orderDiscountLessThan",
    description: "ConversionDefinitions.orderDiscountLessThanHelp",
    valueRequired: true,
    isCurrency: true,
  },
  {
    id: "ORDER_DISCOUNT_GREATER_THAN_EQUALS",
    type: "DECIMAL",
    label: "ConversionDefinitions.orderDiscountGreaterThanEquals",
    description: "ConversionDefinitions.orderDiscountGreaterThanEqualsHelp",
    valueRequired: true,
    isCurrency: true,
  },
  {
    id: "RECOMMENDATION_PURCHASED",
    label: "ConversionDefinitions.recommendationPurchased",
    type: "BASE",
    description: "ConversionDefinitions.recommendationPurchasedHelp"
  },
  {
    id: "RECOMMENDATION_CLICKED",
    label: "ConversionDefinitions.recommendationClicked",
    type: "BASE",
    description: "ConversionDefinitions.recommendationClickedHelp"
  },
  {
    id: "WIDGET_ACTION_CATEGORY_MATCHES",
    label: "ConversionDefinitions.widgetActionCategoryMatches",
    type: "ENUM",
    description: "ConversionDefinitions.widgetActionCategoryMatchesHelp",
    operators: [
      { value: 'IN', split: true }
    ],
    valueRequired: true,
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    options: [
      { value: 'CTA', label: 'widgetActionCategory.CTA' },
      { value: 'CLOSE', label: 'widgetActionCategory.CLOSE' },
      { value: 'NONE', label: 'widgetActionCategory.NONE' },
    ]
  },
  {
    id: "WIDGET_ACTION_CATEGORY_DOES_NOT_MATCH",
    label: "ConversionDefinitions.widgetActionCategoryDoesNotMatch",
    type: "ENUM",
    description: "ConversionDefinitions.widgetActionCategoryDoesNotMatchHelp",
    operators: [
      { value: 'IN', split: true }
    ],
    valueRequired: true,
    hideOperatorsInEditor: true,
    hideOperatorsInList: true,
    options: [
      { value: 'CTA', label: 'widgetActionCategory.CTA' },
      { value: 'CLOSE', label: 'widgetActionCategory.CLOSE' },
      { value: 'NONE', label: 'widgetActionCategory.NONE' },
    ]
  },
]
export default data