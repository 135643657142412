import {orderBy} from 'lodash'
import {dayjs,mysqlFormat} from './DateUtil'
const latinMap = require('./data/latinMap.json')


export default class Utils {

  static sort(arr, cols = [], orders = [], strCols = []) {
    const sorting = cols.map((col) => {
      if (typeof col === 'function') return col
      if (strCols.includes(col)) {
        return (item) => item[col] ? Utils.latinise(item[col]).trim().toLowerCase() : null
      }
      return (item) => item[col]
    })
    return orderBy(arr, sorting, orders)
  }

  static mapBy(array, prop) {
    return array.reduce((map, d) => {
      map[d[prop]] = d
      return map
    }, {})
  }

  static latinise(str) {
    return str.replace(/[^A-Za-z]/g, (char) => {
      return latinMap[char] || char
    })
  }

  static arrayToCsvString(arr, addBom = true) {
    var str = addBom ? '\ufeff' : ''
    arr.forEach((arr2) => {
      str += arr2.map((el) => {
        return `"${('' + el).replace(/"/g, '\\"')}"`
      }).join(',') + '\n'
    })
    return str
  }

  static makeCancelablePromise(promise) {
    let hasCanceled_ = false
    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then((val) =>
        hasCanceled_ ? reject({isCanceled: true}) : resolve(val)
      )
      promise.catch((error) =>
        hasCanceled_ ? reject({isCanceled: true}) : reject(error)
      )
    })
    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true
      }
    }
  }

  static uploadWithProgress(url, options, onProgress) {
    // console.log(url, options, onProgress)
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()
      req.open(options.method, url)
      if (options.headers) {
        Object.keys(options.headers).forEach(key => {
          req.setRequestHeader(key, options.headers[key])
        })
      }
      if (onProgress) {
        req.upload.addEventListener('progress', event => {
          onProgress({
            loaded: event.loaded,
            total: event.total,
            percent: event.loaded / event.total * 100
          })
        })
      }
      req.addEventListener('load', () => {
        if (/^2/.test(req.status)) {
          resolve(true)
        } else {
          reject(req.responseText)
        }
      })
      req.send(options.body)
    })
  }

  static copyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  static safeJsonParse(str) {
    try {
      return JSON.parse(str)
    } catch (e) {
      return null
    }
  }

  static utcToAccountTz(utcRawTime, accountTz) {
    return dayjs.utc(utcRawTime).tz(accountTz).format(mysqlFormat)
  }

  static accountCurrentTimeInTz(accountTz) {
    return dayjs().tz(accountTz).format(mysqlFormat)
  }

  static dateYearOld(date, accountTz) {
    const yearAgo = dayjs.tz(dayjs().utc(), 'UTC')
      .tz(accountTz)
      .subtract(1, 'year')
    return date && dayjs.tz(date, accountTz).isBefore(yearAgo)
  }

  // https://dev.to/rajnishkatharotiya/get-byte-size-of-the-string-in-javascript-20jm
  static byteSize(str) {
    return new Blob([str]).size
  }
  
}