var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"title":"Choose an Account","primaryAction":{
    label: 'Switch',
    onAction: _vm.confirm
  },"secondaryActions":[{
    label: 'Cancel',
    onAction: _vm.cancel
  }],"visible":_vm.visible,"overflowVisible":""}},[_c('Select',{staticClass:"Modal-input",attrs:{"options":_vm.accounts,"searchable":true},model:{value:(_vm.accountToSwitchToId),callback:function ($$v) {_vm.accountToSwitchToId=$$v},expression:"accountToSwitchToId"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }