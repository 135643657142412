<template>
  <Modal
    title="Login"
    :primaryAction="{
      label: 'Login',
      onAction: login
    }"
    :visible="visible"
  >
    <FormLayout>
      <TextField
        label="Email"
        v-model="email"
        ref="email"
        @keydown.enter="login"
        :disabled="trying"
      />
      <TextField
        v-if="visible"
        label="Password"
        v-model="password"
        ref="password"
        type="password"
        @keydown.enter="login"
        :disabled="trying"
      />
    </FormLayout>
  </Modal>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      trying: false,
      email: '',
      password: '',
      error: '',
      message: '',
      resolve: null,
      reject: null,
      visible: false
    }
  },
  afterEnter() {
    this.$refs.email.focus()
  },
  methods: {
    show(message) {
      if (message) {
        this.message = message
      }
      this.visible = true
      return new Promise((res, rej) => {
        this.resolve = res
        this.reject = rej
      })
    },
    login() {
      this.error = ''
      this.message = ''
      if (!this.email) {
        this.error = 'Please enter an email'
        return
      }
      if (!this.password) {
        this.error = 'Please enter a password'
        return
      }
      this.trying = true
      return this.api.login(this.email.trim(), this.password.trim())
        .then((response) => {
          this.visible = false
          this.resolve(response)
        })
        .catch((err) => {
          this.error = err
        })
        .finally(() => {
          this.trying = false
        })
    }
  }
};
</script>
