<template functional>
  <div id="CampaignNotesExperiment">
    <template v-if="typeof props.note.notes === 'string'">
      {{$options.methods.parseSimpleNote(props.note.notes, parent.$t)}}
    </template>
    <template v-else-if="props.note.objectAction === 'CREATE'">
      {{parent.$t('CampaignNotes.addedExperiment', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectAction === 'DELETE'">
      {{parent.$t('CampaignNotes.deletedExperiment', { value: props.note.objectName })}}
    </template>
    <template v-else>
      <div v-if="props.note.notes['SEGMENT_ACTION']" class="note-group-section">
        <b>{{parent.$t('CampaignNotes.actionsUpdated')}}</b>
        <List tight class="offer-list">
          <ListItem v-for="(noteInner, index) in props.note.notes['SEGMENT_ACTION']" :key="index">
            <template v-if="!noteInner.newValue && !noteInner.oldValue">
              {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
            </template>
            <div v-else-if="noteInner.newValue">
              {{parent.$t('CampaignNotes.addedActionToExperiment')}} <PageLink :url="$options.methods.getActionUrl(noteInner.newValue, props.campaignId)" external>{{$options.methods.formatAction(noteInner.newValue).name}}</PageLink>
            </div>
            <div v-else-if="noteInner.oldValue">
              {{parent.$t('CampaignNotes.removedActionFromExperiment')}} <PageLink :url="$options.methods.getActionUrl(noteInner.oldValue, props.campaignId)" external>{{$options.methods.formatAction(noteInner.oldValue).name}}</PageLink>
            </div>
          </ListItem>
        </List>
      </div>
      <div v-if="props.note.notes['OTHER']" class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes['OTHER']" :key="index" class="simple-note-content">
          <template v-if="noteInner.newValue !== undefined">
            <div v-html="parent.$t('CampaignNotes.fromTo', {
              attr: $options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t),
              old: $options.methods.fixValue(noteInner.oldValue, parent.$t, noteInner.attribute),
              new: $options.methods.fixValue(noteInner.newValue, parent.$t, noteInner.attribute),
            })"/>
          </template>
          <template v-else-if="noteInner.newValue === undefined && noteInner.oldValue === undefined">
            {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {capitalize} from 'lodash'
export default {
  name: 'CampaignNotesSegment',
  props: {
    note: {
      type: Object,
      required: true
    },
    campaignId: {
      type: Number,
      required: true
    }
  },
  methods: {
    parseSimpleNote(note){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      return returnNoteStr
    },
    getIsBothJson(noteInner) {
      let isBothJson = true
      try {
        JSON.parse(noteInner.oldValue)
      } catch(e) {
        isBothJson = false
      }
      try {
        JSON.parse(noteInner.newValue)
      } catch(e) {
        isBothJson = false
      }
      return isBothJson
    },
    getOldValueJson(noteInner) {
      let oldValueJson
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch (e) {
        return ''
      }
      return oldValueJson
    },
    getNewValueJson(noteInner) {
      let newValueJson
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch (e) {
        return ''
      }
      return newValueJson
    },
    parseAttributeForTrans(attribute, translationFunction) {
      return translationFunction(`${attribute}`)
    },
    getActionUrl(strAction, campaignId) {
      let action = {}
      try {
        action = JSON.parse(strAction)
      } catch (e) {
        return ''
      }
      return `/#/campaigns/${campaignId}/segment-actions/edit/${action.id}`
    },
    formatAction(strAction) {
      let action = {}
      try {
        action = JSON.parse(strAction)
      } catch (e) {
        return {}
      }
      return action
    },
    fixValue(value, translationFunction, attribute) {
      if (value === null || value === undefined || value === '') {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'string' && ['Status'].includes(attribute)) {
        return translationFunction(capitalize(value))
      }
      if (typeof(value) === 'object') {
        return JSON.stringify(value)
      }
      return value
    },
  }
}
</script>
