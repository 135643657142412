<template>
  <Page :title="$t('About')">
    <Layout>
      <LayoutSection>

        <Card :title="$t('Version')">
          <CardSection>
            <TextContainer>
              <div>{{ $t('User') }}: <strong>{{dayjs(version.version).format('YYYYMMDD')}}.{{ version.deployVersion }}</strong></div>
              <div>{{ $t('Server') }}: <strong>{{dayjs(theUser.serverVersion).format('YYYYMMDD')}}.{{ theUser.serverDeployVersion }}</strong></div>
            </TextContainer>
          </CardSection>
        </Card>

        <Card :title="$t('Copyrights')">
          <CardSection>
            <TextContainer>
              <div v-html="$t('about.copyrightsHelpHtml')"></div>
            </TextContainer>
          </CardSection>
        </Card>

      </LayoutSection>
    </Layout>
  </Page>
</template>

<script>
import {dayjs} from '../classes/utils/DateUtil'
const version = require('../version.json')

export default {
  name: 'About',
  data() {
    return {
      version,
      dayjs
    }
  },
  mounted() {
    this.eventHub.$emit('setNavigation')
    this.eventHub.$emit('setTitle', this.$t('About'), 'AboutLayout')
    this.eventHub.$emit('setResources')
  }
}
</script>

<style lang="scss" scoped>
.bookmark {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
</style>