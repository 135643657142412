<template functional>
  <div id="CampaignNotesSegment">
    <template v-if="typeof props.note.notes === 'string'">
      {{$options.methods.parseSimpleNote(props.note.notes, parent.$t)}}
    </template>
    <template v-else-if="props.note.objectAction === 'CREATE'">
      {{parent.$t('CampaignNotes.addedSegment', { value: props.note.objectName })}}
    </template>
    <template v-else-if="props.note.objectAction === 'DELETE'">
      {{parent.$t('CampaignNotes.deletedSegment', { value: props.note.objectName })}}
    </template>
    <template v-else>
      <div v-if="props.note.notes['RULESET']" class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes['RULESET']" :key="index">
          <b>{{parent.$t('CampaignNotes.removedRuleset')}}</b>
          <!-- <div v-for="(obj, i) in $options.methods.getNewValueJson(noteInner)" :key="i" class="rule">
            <div class="delete-rule">
              <div class="rule-text">
                <rule-summary :rule="obj" state="removed"/>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <pre>{{props.note.notes['RULESET']}}</pre> -->
      </div>
      <div v-if="props.note.notes['RULE']" class="note-group-section">
        <b>{{parent.$t('CampaignNotes.rulesUpdated')}}</b>
        <div v-for="(noteInner, index) in props.note.notes['RULE']" :key="index" class="rule">
          <div v-if="$options.methods.getIsBothJson(noteInner)" class="change-rule">
            <div class="rule-text">
              <rule-summary v-if="$options.methods.getOldValueJson(noteInner).id" :rule="$options.methods.getOldValueJson(noteInner)" state="removed"/>
              <div v-else>{{parent.$t('CampaignNotes.cannotParseRule')}}</div>
              <rule-summary v-if="$options.methods.getNewValueJson(noteInner).id" :rule="$options.methods.getNewValueJson(noteInner)"/>
              <div v-else>{{parent.$t('CampaignNotes.cannotParseRule')}}</div>
            </div>
          </div>
          <div v-else-if="$options.methods.getNewValueJson(noteInner) && !$options.methods.getOldValueJson(noteInner)" class="new-rule">
            <div class="rule-text"> 
              <rule-summary v-if="$options.methods.getNewValueJson(noteInner).id" :rule="$options.methods.getNewValueJson(noteInner)"/>
              <div v-else>{{parent.$t('CampaignNotes.cannotParseRule')}}</div>
            </div>
          </div>
          <div v-else-if="!$options.methods.getNewValueJson(noteInner) && $options.methods.getOldValueJson(noteInner)" class="delete-rule">
            <div class="rule-text">
              <rule-summary v-if="$options.methods.getOldValueJson(noteInner).id" :rule="$options.methods.getOldValueJson(noteInner)" state="removed"/>
              <div v-else>{{parent.$t('CampaignNotes.cannotParseRule')}}</div>
            </div>
          </div>

        </div>
      </div>
      <div v-if="props.note.notes['OFFER']" class="note-group-section">
        <b>{{parent.$t('CampaignNotes.offersUpdated')}}</b>
      </div>
      <div v-if="props.note.notes['SEGMENT_ACTION']" class="note-group-section">
        <b>{{parent.$t('CampaignNotes.actionsUpdated')}}</b>
        <List tight class="offer-list">
          <ListItem v-for="(noteInner, index) in props.note.notes['SEGMENT_ACTION']" :key="index">
            <template v-if="!noteInner.newValue && !noteInner.oldValue">
              {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
            </template>
            <div v-else-if="noteInner.newValue">
              {{parent.$t('CampaignNotes.addedActionToSegment')}} <PageLink :url="$options.methods.getActionUrl(noteInner.newValue, props.campaignId)" external>{{$options.methods.formatAction(noteInner.newValue).name}}</PageLink>
            </div>
            <div v-else-if="noteInner.oldValue">
              {{parent.$t('CampaignNotes.removedActionFromSegment')}} <PageLink :url="$options.methods.getActionUrl(noteInner.oldValue, props.campaignId)" external>{{$options.methods.formatAction(noteInner.oldValue).name}}</PageLink>
            </div>
          </ListItem>
        </List>
      </div>
      <div v-if="props.note.notes['EXPERIMENT']" class="note-group-section">
        <b>{{parent.$t('CampaignNotes.experimentsUpdated')}}</b>
        <List tight class="offer-list">
          <ListItem v-for="(noteInner, index) in props.note.notes['EXPERIMENT']" :key="index">
            <template v-if="!noteInner.newValue && !noteInner.oldValue">
              {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
            </template>
            <div v-else-if="noteInner.newValue">
              {{parent.$t('CampaignNotes.addedExperimentToSegment')}} <PageLink :url="$options.methods.getExperimentUrl(noteInner.newValue, props.campaignId)" external>{{$options.methods.formatExperiment(noteInner.newValue).name}}</PageLink>
            </div>
            <div v-else-if="noteInner.oldValue">
              {{parent.$t('CampaignNotes.removedExperimentFromSegment')}} <PageLink :url="$options.methods.getExperimentUrl(noteInner.oldValue, props.campaignId)" external>{{$options.methods.formatExperiment(noteInner.oldValue).name}}</PageLink>
            </div>
          </ListItem>
        </List>
      </div>
      <div v-if="props.note.notes['OTHER']" class="note-group-section">
        <div v-for="(noteInner, index) in props.note.notes['OTHER']" :key="index" class="simple-note-content">
          <template v-if="noteInner.attribute === 'RULESET'">
            <!-- IGNORE THIS -->
          </template>
          <template v-else>
            <template 
              v-if="$options.methods.getDoAsJson($options.methods.getOldValueJson(noteInner), $options.methods.getNewValueJson(noteInner)) 
                && typeof $options.methods.getOldValueJson(noteInner) === 'object' 
                && typeof $options.methods.getNewValueJson(noteInner) === 'object'"
            >
              <b>{{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}</b>
              <table style="margin-top: 2px;" class="table table-bordered change-table">
                <tr>
                  <td></td>
                  <td><b>{{parent.$t('Old Value')}}</b></td>
                  <td><b>{{parent.$t('New Value')}}</b></td>
                </tr>
                <template v-for="(key, i) of Object.keys($options.methods.getOldValueJson(noteInner))">
                  <tr v-if="!$options.methods.isEqualOldNew($options.methods.getOldValueJson(noteInner)[key], $options.methods.getNewValueJson(noteInner)[key])" :key="i">
                    <td>{{$options.methods.niceKey(key)}}</td>
                    <td>{{$options.methods.getOldValueJson(noteInner)[key]}}</td>
                    <td>{{$options.methods.getNewValueJson(noteInner)[key]}}</td>
                  </tr>
                </template>
              </table>
            </template>
            <template v-else-if="$options.methods.getDoAsJson($options.methods.getOldValueJson(noteInner), $options.methods.getNewValueJson(noteInner))">
              <b>{{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}</b>
              <table style="margin-top: 2px;" class="table table-bordered change-table">
                <template v-for="(key, i) of Object.keys($options.methods.getNewValueJson(noteInner))">
                  <tr v-if="$options.methods.getNewValueJson(noteInner)[key] !== null && $options.methods.getNewValueJson(noteInner)[key] !== undefined" :key="i">
                    <td>{{$options.methods.niceKey(key)}}</td>
                    <td>{{$options.methods.getNewValueJson(noteInner)[key]}}</td>
                  </tr>
                </template>
              </table>
            </template>
            <template v-else-if="noteInner.newValue !== undefined">
              <div v-html="parent.$t('CampaignNotes.fromTo', {
                attr: $options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t),
                old: $options.methods.fixValue(noteInner.oldValue, parent.$t, noteInner.attribute),
                new: $options.methods.fixValue(noteInner.newValue, parent.$t, noteInner.attribute),
              })"/>
            </template>
            <template v-else-if="noteInner.newValue === undefined && noteInner.oldValue === undefined">
              {{$options.methods.parseAttributeForTrans(noteInner.attribute, parent.$t)}}
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {capitalize} from 'lodash'
export default {
  name: 'CampaignNotesSegment',
  props: {
    note: {
      type: Object,
      required: true
    },
    campaignId: {
      type: Number,
      required: true
    }
  },
  methods: {
    parseSimpleNote(note, translationFunction){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      returnNoteStr = returnNoteStr.replace(/^Added segment/, translationFunction('CampaignNotes.Added segment'))
      returnNoteStr = returnNoteStr.replace(/^Deleted segment/, translationFunction('CampaignNotes.Deleted segment'))
      return returnNoteStr
    },
    getIsBothJson(noteInner) {
      let isBothJson = true
      try {
        JSON.parse(noteInner.oldValue)
      } catch(e) {
        isBothJson = false
      }
      try {
        JSON.parse(noteInner.newValue)
      } catch(e) {
        isBothJson = false
      }
      return isBothJson
    },
    getOldValueJson(noteInner) {
      let oldValueJson
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch (e) {
        return ''
      }
      return oldValueJson
    },
    getNewValueJson(noteInner) {
      let newValueJson
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch (e) {
        return ''
      }
      return newValueJson
    },
    parseAttributeForTrans(attribute, translationFunction) {
      if (attribute.includes('Offer ') && attribute.includes(' was added to the segment')) {
        attribute = attribute.replace(/^Offer/, translationFunction('CampaignNotes.Offer'))
        attribute = attribute.replace(/was added to the segment$/, translationFunction('CampaignNotes.was added to the segment'))
      } else if (attribute.includes('Offer ') && attribute.includes(' was removed from the segment')) {
        attribute = attribute.replace(/^Offer/, translationFunction('CampaignNotes.Offer'))
        attribute = attribute.replace(/was removed from the segment$/, translationFunction('CampaignNotes.was removed from the segment'))
      } else {
        attribute = translationFunction(`${attribute}`)
      }
      return attribute
    },
    isEqualOldNew(oldValue, newValue) {
      if (oldValue === null || oldValue === undefined) oldValue = ''
      if (newValue === null || newValue === undefined) newValue = ''
      return JSON.stringify(oldValue) === JSON.stringify(newValue)
    },
    formatAction(strAction) {
      let action = {}
      try {
        action = JSON.parse(strAction)
      } catch (e) {
        return {}
      }
      return action
    },
    formatExperiment(strEx) {
      let ex = {}
      try {
        ex = JSON.parse(strEx)
      } catch (e) {
        return {}
      }
      return ex
    },
    getOfferUrl(strOffer, campaignId) {
      let offer = {}
      try {
        offer = JSON.parse(strOffer)
      } catch (e) {
        return ''
      }
      return `/#/campaigns/${campaignId}/offers/edit/${offer.id}`
    },
    getExperimentUrl(strEx, campaignId) {
      let experiment = {}
      try {
        experiment = JSON.parse(strEx)
      } catch (e) {
        return ''
      }
      return `/#/campaigns/${campaignId}/experiments/edit/${experiment.id}`
    },
    getActionUrl(strAction, campaignId) {
      let action = {}
      try {
        action = JSON.parse(strAction)
      } catch (e) {
        return ''
      }
      return `/#/campaigns/${campaignId}/segment-actions/edit/${action.id}`
    },
    getDoAsJson(oldValueJson, newValueJson) {
      let doAsJson = false
      if (oldValueJson && newValueJson && (typeof oldValueJson === 'object')) {
        doAsJson = true
      }
      return doAsJson
    },
    niceKey(val) {
      return capitalize(val)
    },
    fixValue(value, translationFunction, attribute = null) {
      const segTypeMap = {
        'include': translationFunction('campaignEdit.segment.type.standard'),
        'exit-intent': translationFunction('campaignEdit.segment.type.exitIntent'),
        'display-none': translationFunction('campaignEdit.segment.type.hideOffers'),
        'exclude': translationFunction('campaignEdit.segment.type.doNotTarget'),
        'ignore-user': translationFunction('campaignEdit.segment.type.ignoreUser'),
      }
      if (value === null || value === undefined || value === '') {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'boolean') {
        return translationFunction(value ? 'filters.TRUE.label' : 'filters.FALSE.label')
      }
      if (typeof(value) === 'string' && attribute === 'Widget' && value === 'Default') {
        return translationFunction('Default')
      }
      if (typeof(value) === 'string' && attribute === 'Type') {
        return segTypeMap[value]
      }
      if (typeof(value) === 'string' && attribute === 'Status') {
        return translationFunction(capitalize(value))
      }
      if (typeof(value) === 'object') {
        return JSON.stringify(value)
      }
      if (typeof(value) === 'string' && value.match(/19[0-9][0-9]-[0-9][0-9]-[0-9][0-9]/) && parseInt(value.substr(0, 4), 10) < 1980) {
        return `<i>${translationFunction('merch.empty')}</i>`
      }
      if (typeof(value) === 'string' && value.indexOf('cloudfront.net') > -1) {
        return `<div style="background: url(${value});
          width: 30px;
          height: 30px;
          background-size: contain;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center bottom;
        "></div>`
      }
      return value
    },
  }
}
</script>
