<template>
  <Banner v-if="error" :title="$t('Errors')" status="warning" class="banner">
    {{$t('validation.bannerErrorMessage')}}
  </Banner>
  <Banner v-else :title="$t('validation.bannerValidTitle')" status="info" class="banner">
    {{$t('validation.bannerValidMessage')}}
  </Banner>
</template>

<script>
export default {
  props: {
    error:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 15px;
  width: 100%;
}
</style>