import {dayjs, mysqlFormat} from './DateUtil'

export function outsideValidityPeriod(offer, campaignTz) {
  const {validFrom, validUntil} = offer
  const now = dayjs.utc().tz(campaignTz)
  return (
    (validFrom && dayjs.tz(validFrom, campaignTz).isAfter(now)) ||
    (validUntil && dayjs.tz(validUntil, campaignTz).isBefore(now))
  )
}

export function outsideActionSchedule(action, campaignTz) {
  const {startDate, endDate, days, startTime, endTime} = action
  const now = dayjs.utc().tz(campaignTz)
  let start = startDate ? dayjs(startDate) : null
  let end = endDate ? dayjs(endDate) : null
  if (days || startTime || endTime) {
    // recurrence schedule
    const formattedDays = days && days.length ? days : [0,1,2,3,4,5,6]

    while (start && !formattedDays.includes(start.day())) {
      start = start.add(1, 'day')
    }
    if (start && startTime && start.format('HH:mm:ss') < startTime) {
      start = dayjs(`${start.format('YYYY-MM-DD')} ${startTime}`)
    }

    while (end && !formattedDays.includes(end.day())) {
      end = end.subtract(1, 'day')
    }
    if (end && endTime && end.format('HH:mm:ss') > endTime) {
      end = dayjs(`${end.format('YYYY-MM-DD')} ${endTime}`)
    }
  } 
  return (
    (start && dayjs.tz(start.format(mysqlFormat), campaignTz).isAfter(now)) ||
    (end && dayjs.tz(end.format(mysqlFormat), campaignTz).isBefore(now))
  )
}

export function checkOfferCodeRunout(offer) {
  return offer.promoType === 'file' && offer.status === 'runOut'
}


// --------------------------------------
// Offer validities overlap
export function isActionOfferOverlap(action, allOffers) {
  const offers = allOffers
    .filter(o => action.offerIds.includes(o.id))
  return activeOffersOverlap(offers)
}

function activeOffersOverlap(offers) {
  const beginningOfTime = '2000-01-01 00:00:00'
  const endOfTime = '2100-01-01 00:00:00'
  const activeOffers = offers.filter(o => !o.isDisabled)
  return activeOffers.some(o1 => {
    const validFrom1 = dayjs(o1.validFrom || beginningOfTime)
    const validUntil1 = dayjs(o1.validUntil || endOfTime)
    return activeOffers.some(o2 => {
      const validFrom2 = dayjs(o2.validFrom || beginningOfTime)
      const validUntil2 = dayjs(o2.validUntil || endOfTime)
      return o1.id !== o2.id 
        && (validFrom1.isBefore(validUntil2) && validUntil1.isAfter(validFrom2))
    })
  })
}

// --------------------------------------
// no valid offer for action with widget with offer vars
export function noValidOffer(action, allWidgets, allOffers, campaignTz) {
  const widget = allWidgets.find(w => w.id === action.widgetId)
  // dont validate if widget has published offer vars or has persistent view
  if (!widget || !widget.publishedHasOfferVars || widget.publishedIsPersistent) return false
  const offers = allOffers.filter(o => action.offerIds.includes(o.id))
  if (!action.offerIds.length) return true
  return offers.every(offer => {
    return offer.isDisabled 
      || outsideValidityPeriod(offer, campaignTz)
      || checkOfferCodeRunout(offer)
  })
}

// --------------------------------------
// invalid widget on action
export function invalidWidget(action, allWidgets) {
  if (!action.widgetId) return false
  const widget = allWidgets.find(w => w.id === action.widgetId)
  return !widget || widget.status !== 'active'
}

// --------------------------------------
// invalid product display with widget
export function productDisplayIsInvalid(widget, productDisplay, modelInfoMap) {
  if (!productDisplay || productDisplay.status !== 'active') return true
  // they can do whatever they want for custom widgets
  if (widget.type === 'custom') return false
  // checks for mismatch type (productRec widget with activity display or vice versa)
  const modelType = modelInfoMap[productDisplay.model] 
    ? modelInfoMap[productDisplay.model].type
    : ''
  return (
    widget.publishedProductDisplayType === 'rec' 
    && modelType.startsWith('PRODUCT-ACTIVITY')
  ) || (
    widget.publishedProductDisplayType === 'activity'
    && !modelType.startsWith('PRODUCT-ACTIVITY')
  )
}
export function invalidProductDisplay(action, allWidgets, allProductDisplays, productDisplayModelMap) {
  if (!action.widgetId || !action.widgetProductDisplayId) return false
  const widget = allWidgets.find(w => w.id === action.widgetId)
  if (!widget) return false
  const productDisplay = allProductDisplays.find(d => d.id === action.widgetProductDisplayId)
  return productDisplayIsInvalid(widget, productDisplay, productDisplayModelMap)
}

// --------------------------------------
// invalid variant on action
export function invalidVariant(variant, availableVariants) {
  if (!variant) return false
  return !availableVariants.includes(variant)
}

// --------------------------------------
// no valid action on experiment
export function noValidAction(
  experiment, 
  allActions, 
  allWidgets, 
  widgetVariantMap, 
  allOffers,
  allProductDisplays,
  productDisplayModelMap, 
  campaignTz
) {
  const actions = allActions.filter(a => experiment.actionIds.includes(a.id))
  if (!experiment.actionIds.length) return true
  return actions.every(action => {
    return action.status === 'disabled' 
      || outsideActionSchedule(action, campaignTz)
      || isActionOfferOverlap(action, allOffers)
      || noValidOffer(action, allWidgets, allOffers, campaignTz)
      || invalidWidget(action, allWidgets)
      || invalidVariant(action.widgetVariant, widgetVariantMap[action.widgetId] || [])
      || invalidProductDisplay(action, allWidgets, allProductDisplays, productDisplayModelMap)
  })
}

// --------------------------------------
// conflicting actions in segment
function calculateTriggerableActionStatesOnSegment(
  segment,
  allActions,
  allExperiments
) {
  // cartesian product: https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript
  // slightly adjusted for my liking
  const cartesian = (a) => {
    return a.reduce((a, b) => {
      return a.flatMap(d => {
        return b.map(e => [d, e].flat())
      })
    }, [[]])
  }

  const getActiveIds = (ids, type) => {
    return ids.filter(id => {
      const obj = type === 'action' 
        ? allActions.find(action => action.id === id) 
        : allExperiments.find(experiment => experiment.id === id) 
      return obj && obj.status === 'active'
    })
  }

  const activeActionIds = getActiveIds(segment.actionIds, 'action')
  const activeExperimentIds = getActiveIds(segment.experimentIds, 'experiment')
  const triggerableActionIds = activeActionIds.map(aId => [aId])
  const triggerableExperimentActionIds = activeExperimentIds.map(eId => {
    const experiment = allExperiments.find(e => e.id === eId)
    return getActiveIds(experiment ? experiment.actionIds : [], 'action')
  }).filter(aIds => aIds.length)
  return cartesian(triggerableActionIds.concat(triggerableExperimentActionIds))
}

// checks if list of actions have overlapping widgets between two actions that share validity periods
function actionWidgetsOverlap(actions) {
  const beginningOfTime = '2000-01-01 00:00:00'
  const endOfTime = '2100-01-01 00:00:00'
  return actions.some(a1 => {
    const start1 = dayjs(a1.startDate || beginningOfTime)
    const startDailyTime1 = a1.startTime || '00:00:00'
    const end1 = dayjs(a1.endDate || endOfTime)
    const endDailyTime1 = a1.endTime || '23:59:59'
    return actions.some(a2 => {
      const start2 = dayjs(a2.startDate || beginningOfTime)
      const startDailyTime2 = a2.startTime || '00:00:00'
      const end2 = dayjs(a2.endDate || endOfTime)
      const endDailyTime2 = a2.endTime || '23:59:59'
      return a1.id !== a2.id // same actions dont count
        && a1.widgetId === a2.widgetId // have the same widget
        && (start1.isBefore(end2) && end1.isAfter(start2)) // overall validity dates overlap
        && (startDailyTime1 < endDailyTime2 && endDailyTime1 > startDailyTime2) // daily schedule overlaps
        && (!a1.days || !a2.days || a1.days.filter(d => a2.days.includes(d)).length) // days of the week overlap (not set means every day)
    })
  }) 
}

export function checkSegmentIdenticalActions(segment, allActions, allExperiments) {
  const combinations = calculateTriggerableActionStatesOnSegment(segment, allActions, allExperiments)
  // checks for duplicates in nested arrays 
  // ex: [[1, 2], [3, 4, 3]] --> 3 is duplicate inside second array
  return combinations.some(actionIds => {
    return actionIds.some((actionId, index) => {
      return actionIds.indexOf(actionId) !== index
    })
  })
}

export function checkSegmentIdenticalWidgets(segment, allActions, allExperiments) {
  const actionCombinations = calculateTriggerableActionStatesOnSegment(segment, allActions, allExperiments)
  return actionCombinations.some(actionIds => {
    return actionWidgetsOverlap(allActions.filter(action => actionIds.includes(action.id)))
  })
}
