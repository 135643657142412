var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{attrs:{"title":_vm.$t('Image Library'),"primaryAction":{
    label: _vm.$t('Choose'),
    onAction: _vm.choose,
    disabled: !_vm.selectedImage
  },"secondaryActions":[
    {
      label: _vm.$t('Cancel'),
      onAction: () => _vm.visible = false
    }
  ],"visible":_vm.visible},on:{"close":function($event){_vm.visible = false}}},[(_vm.loading)?_c('LoadingLayout'):_c('div',{staticClass:"library",on:{"mouseleave":_vm.stopDrag,"dragend":_vm.stopDrag,"dragover":_vm.onDragOver,"drop":_vm.onDrop}},[(_vm.isDragging)?_c('div',{staticClass:"dropper"},[_vm._v(_vm._s(_vm.$t('Drop images'))+" ...")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"upload",on:{"click":_vm.promptUpload}},[(_vm.uploadingImages)?_c('div',{staticClass:"upload-status"},[_vm._v(_vm._s(_vm.$t('Uploading'))+" ...")]):_vm._e(),_c('div',{staticClass:"upload-icon"}),_c('div',{staticClass:"upload-message"},[_c('div',{staticClass:"upload-title"},[_vm._v(_vm._s(_vm.$t('Upload Image')))]),_c('div',{staticClass:"upload-hint"},[_vm._v(_vm._s(_vm.$t('ImageLibrary.Or drag and drop to upload')))])]),_c('form',{ref:"form"},[_c('input',{ref:"fileInput",staticClass:"fileInput",attrs:{"type":"file","name":"file","multiple":""},on:{"change":_vm.processFileInput}})])]),(_vm.images.length === 0)?_c('div',{staticClass:"no-images"},[_vm._v(_vm._s(_vm.$t('ImageLibrary.noImagesMessage')))]):_vm._e(),(_vm.images.length)?_c('div',{staticClass:"images"},_vm._l((_vm.images),function(image){return _c('div',{key:image.id,staticClass:"image",class:{ 'image--selected': _vm.selectedImage == image },style:({ 'background-image': `url('${image.cdnUrl}')` }),on:{"click":function($event){_vm.selectedImage = image},"dblclick":function($event){_vm.selectedImage = image; _vm.choose();}}},[_c('div',{staticClass:"delete",on:{"click":function($event){return _vm.deleteImage(image)}}},[_vm._v("✕")]),_c('div',{staticClass:"dimensions"},[_vm._v(_vm._s(image.width)+"px x "+_vm._s(image.height)+"px")])])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }