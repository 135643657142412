<template>
  <div>
    <Onboarder
      v-if="state"
      :initialState="state"
      :print="true"
      :locale="locale"
      :integrations="integrations"
    />
    <!-- <pre>{{state}}</pre> -->
  </div>
</template>

<script>
export default {
  name: 'OnboarderPrint',
  components: {
    Onboarder: window.CdpLib.components.Onboarder
  },
  data() {
    return {
      state: null,
      integrations: [],
      locale: 'en'
    }
  },
  mounted() {
    const secret = this.$route.params.secret
    this.locale = this.$route.params.locale || 'en'
    Promise.all([
      this.api.getCdpOnboardingStateBySecret(secret),
      this.api.getCdpOnboardingIntegrations()
    ])
      .then(([state, integrations]) => {
        this.integrations = integrations
        this.state = state
        this.$nextTick(() => {
          setTimeout(() => {
            window.pdfReady = true
          }, 200)
        })
      })
      .catch(this.apiCatch)
  }
}
</script>

<style>

</style>