<template>
  <div class="Main-Inner">
    <div class="Main-Content">
      <LoadingLayout />
    </div>
  </div>
</template>

<script>

export default {
  name: 'LogoutLayout',
  async mounted() {
    await this.api.logout()
    this.$router.push('/site/login/')
  }
}
</script>