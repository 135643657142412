<template>
  <div id="SiteReportsLogin" class="Main-Content">

    <Page>
      <LoadingLayout v-if="!error" />

      <Layout v-else>
        <LayoutSection>
          <Card>
            <CardSection>
              <p>{{ error }}</p>
            </CardSection>
          </Card>
        </LayoutSection>
      </Layout>
    </Page>

  </div>
</template>

<script>

export default {
  name: 'SiteReportsLogin',
  data () {
    return {
      error: ''
    }
  },
  mounted() {
    this.tryLogin()
  },
  methods: {
    async tryLogin() {
      const accountId = this.$route.params.accountId
      const onBehalfOfAccountId = this.$route.params.onBehalfOfAccountId
      const email = this.$route.params.email
      const hash = this.$route.params.hash
      const ts = this.$route.params.ts

      this.api.reportsLogin(accountId, onBehalfOfAccountId, email, hash, ts)
      .then(async (loginResponse) => {
        await this.processPostLogin(loginResponse)
        await this.loadExtraDetails()
        this.$router.push('/campaigns/')
      })
      .catch((err) => {
        this.error = err
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.error {
  color: #c00;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
