import Vue from 'vue'
import PortalI18n from 'PortalI18n'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate/dist/vee-validate.full'
import { required, email, min_value, max, max_value } from 'vee-validate/dist/rules';
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
  ...required,
  message: (_, values) => PortalI18n.translate('validation.required', values)
})

extend('email', {
  ...email,
  message: (_, values) => PortalI18n.translate('validation.email', values)
})

extend('min_value', {
  ...min_value,
  message: (_, values) => PortalI18n.translate(`validation.min_value`, values)
})

extend('max_value', {
  ...max_value,
  message: (_, values) => PortalI18n.translate(`validation.max_value`, values)
})

extend('max', {
  ...max,
  message: (_, values) => PortalI18n.translate(`validation.max`, values) 
})